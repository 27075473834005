<div class="footer">
  <div class="content">
    <!-- <p class="content_text">© 2022 Copyright ooblee</p> -->
    <p class="content_text">{{ 'footer.copyright' | translate }}</p>
    <a class="content_text" [href]="envLinks.privacyPolicy" target="_blank">{{
      'footer.policy' | translate
    }}</a>
    <a class="content_text" [href]="envLinks.terms" target="_blank">{{
      'footer.terms' | translate
    }}</a>
  </div>
</div>
