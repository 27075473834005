import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { KissCheckboxIconDirective } from './kiss-checkbox-icon.directive';
import { KissCheckboxComponent } from './kiss-checkbox.component';

@NgModule({
  declarations: [KissCheckboxComponent, KissCheckboxIconDirective],
  imports: [CommonModule, FormsModule],
  exports: [KissCheckboxComponent, KissCheckboxIconDirective],
})
export class KissCheckboxModule {}
