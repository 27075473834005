import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { KissLayoutConfigService } from './services/kiss-layout-config.service';
import { KissLayoutConfig } from './types/kiss-layout-config';

@Component({
  selector: 'kiss-layout',
  templateUrl: './kiss-layout.component.html',
})
export class KissLayoutComponent implements OnDestroy {
  config: KissLayoutConfig;

  private _unsubscribeAll: Subject<void>;
  constructor(private kissLayoutConfigService: KissLayoutConfigService) {
    this._unsubscribeAll = new Subject();

    this.kissLayoutConfigService.onConfigChange.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
      this.config = config;
    });
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
