<svg
  width="10"
  height="10"
  viewBox="0 0 10 10"
  [style]="{ width: size + 'px', height: size + 'px' }"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  class="ooblee-star-rating__item"
  *ngFor="let star of ratingArr"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M4.44785 0.800781C4.2006 0.801148 3.97569 0.936676 3.87167 1.14876L2.77022 3.40146H0.6351C0.377963 3.40146 0.146465 3.54761 0.0482986 3.77179C-0.0498679 3.99596 0.00434346 4.25383 0.186025 4.42562L1.84643 5.99372L1.28088 8.47426C1.22666 8.71418 1.33216 8.96106 1.54754 9.09806C1.76292 9.23468 2.04496 9.23432 2.25961 9.09622L4.44565 7.69625L6.62766 9.09622C6.83571 9.23029 7.1075 9.23542 7.32142 9.11014C7.53497 8.9845 7.64998 8.75154 7.61482 8.51638L7.23754 6.01167L8.91956 4.42342C9.09941 4.252 9.15288 3.99522 9.05545 3.77252C8.95765 3.54945 8.72835 3.40293 8.47268 3.40146H6.12547L5.02403 1.14876C4.92 0.936676 4.69546 0.801148 4.44785 0.800781Z"
    fill="#D3D3D3"
    [attr.fill]="'url(#' + star.id + ')'"
  />

  <defs>
    <linearGradient [id]="star.id">
      <stop
        attr.offset="0%"
        [attr.offset]="star.fill + '%'"
        stop-color="currentColor"
      />
      <stop attr.offset="100%" stop-color="#D3D3D3" />
    </linearGradient>
  </defs>
</svg>
