<ng-container *ngIf="!item.hidden">
  <div class="nav-seperator" *ngIf="item.seperator"></div>

  <!-- item.url -->
  <a
    class="nav-link"
    [ngClass]="[item.classes || '', includesStates(item.activeStateAvoid) ? 'no-active' : '']"
    *ngIf="item.url && !item.externalUrl && !item.function"
    [routerLink]="[item.url]"
    [routerLinkActive]="item.hideActive ? [] : ['active-link']"
    [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
    [queryParams]="item.params"
    [target]="item.openInNewTab ? '_blank' : '_self'"
  >
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>
  <!-- !item.url -->

  <!-- item.externalUrl -->
  <a
    class="nav-link"
    [ngClass]="item.classes"
    *ngIf="item.url && item.externalUrl && !item.function"
    [href]="item.url"
    [target]="item.openInNewTab ? '_blank' : '_self'"
  >
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <!-- item.function -->
  <span
    class="nav-link"
    [ngClass]="item.classes"
    *ngIf="!item.url && item.function"
    (click)="item.function()"
  >
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </span>

  <!-- item.url && item.function -->
  <a
    class="nav-link"
    [ngClass]="item.classes"
    *ngIf="item.url && !item.externalUrl && item.function"
    (click)="item.function()"
    [routerLink]="[item.url]"
    [routerLinkActive]="item.hideActive ? [] : ['active-link']"
    [queryParams]="item.params"
    [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
    [target]="item.openInNewTab ? '_blank' : '_self'"
  >
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <!-- item.externalUrl && item.function -->
  <a
    class="nav-link"
    [ngClass]="item.classes"
    *ngIf="item.url && item.externalUrl && item.function"
    (click)="item.function()"
    [href]="item.url"
    [target]="item.openInNewTab ? '_blank' : '_self'"
  >
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <!-- NAV ITEM -->
  <ng-template #itemContent>
    <!-- ICON -->
    <ng-container *ngTemplateOutlet="itemIcon"></ng-container>
    <!-- !ICON -->

    <span class="nav-title" *ngIf="item.title">
      {{ item.title }}
    </span>

    <span class="nav-translation" *ngIf="item.translation">
      {{ item.translation | translate }}
    </span>
  </ng-template>
  <!-- !ITEM -->
</ng-container>

<!-- NAV ICON -->
<ng-template #itemIcon>
  <div *ngIf="item.icon" class="nav-icon" [ngClass]="[item.icon?.iconClass || '']">
    <img
      *ngIf="item.icon?.imgSrc"
      class="nav-icon__img"
      [ngClass]="{ 'active-icon': !isActive }"
      [src]="item.icon?.imgSrc"
    />

    <img
      *ngIf="item.icon?.imgSrcActive"
      class="nav-icon__img"
      [ngClass]="{ 'active-icon': isActive }"
      [src]="item.icon?.imgSrcActive"
    />

    <i
      *ngIf="item.icon?.fontAwesomeClass"
      class="nav-icon__font-awesome"
      [ngClass]="[item.icon?.fontAwesomeClass || '']"
    ></i>

    <div *ngIf="item.icon?.svg" class="nav-icon__svg" [innerHTML]="item.icon.svg | safeHtml"></div>
  </div>
</ng-template>
<!-- !NAV ICON -->
