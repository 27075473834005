import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { KissDialogModule, KissSvgIconModule } from '@kiss/components/common';
import { ChangePasswordDialogComponent } from './change-password-dialog.component';
import { ChangePasswordDialogService } from './change-password-dialog.service';
import { TranslateModule } from 'app/shared/services/translate/translate.module';

@NgModule({
  declarations: [ChangePasswordDialogComponent],
  imports: [
    CommonModule,
    KissDialogModule,
    ReactiveFormsModule,
    KissSvgIconModule,
    TranslateModule,
  ],
  providers: [ChangePasswordDialogService],
})
export class ChangePasswordDialogModule {}
