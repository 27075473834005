import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { KissDialogConfig, KissLoadingBarService } from '@kiss/components/common';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthenticationService } from '../../../auth/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { TranslatePipe } from '../../../services/translate/translate.pipe';
import { ConfirmedValidator } from '../../../custom-validators/password.validator';
import { Router } from '@angular/router';
import { UserService } from 'app/shared/services/user/user.service';

@Component({
  selector: 'ooblee-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss'],
})
export class ChangePasswordDialogComponent implements OnInit {
  form: FormGroup;
  secondForm: FormGroup;
  lastForm: FormGroup;
  submitted = false;
  step: 'first' | 'second' | 'third' = 'first';
  alert: boolean = false;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private config: KissDialogConfig,
    private _fb: FormBuilder,
    private authService: AuthenticationService,
    private toastr: ToastrService,
    private _kissLoadingBarService: KissLoadingBarService,
    private _translatePipe: TranslatePipe,
    private _router: Router,
    private _userService: UserService
  ) {}

  ngOnInit(): void {
    this.form = this.createForm();
    this.secondForm = this.createSecondForm();

    this.detectChange();
  }

  onClose(): void {
    this.config.data.destroyRef();
  }

  detectChange() {
    this.form
      .get('userName')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((x) => {
        this.submitted = false;
      });
    this.secondForm
      .get('code')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((x) => {
        this.submitted = false;
      });
  }

  createForm() {
    const userData = this._userService.getUserData();

    return this._fb.group({
      userName: [
        userData.email,
        [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')],
      ],
    });
  }

  createSecondForm() {
    return this._fb.group({
      code: [null, [Validators.required]],
    });
  }

  createLastForm() {
    return this._fb.group(
      {
        repeatedPassword: ['', [Validators.required, Validators.minLength(8)]],
        password: ['', [Validators.required, Validators.minLength(8)]],
        userName: [this.form.get('userName').value, [Validators.required]],
        forgotPasswordCode: [this.secondForm.get('code').value, [Validators.required]],
      },
      { validator: ConfirmedValidator('password', 'repeatedPassword') }
    );
  }
  submit() {
    this.submitted = true;
    if (this.form.invalid) {
      const toastNotif = this._translatePipe.transform('notifs.allFieldsRequired');
      this.toastr.info(null, toastNotif);
    } else {
      this.authService
        .sendResetCode(this.form.getRawValue())
        .subscribe({
          next: (res: any) => {
            this.step = 'second';
          },
          error: () => {
            this.form.controls['userName'].setErrors({ incorrect: true });
          },
        })
        .add(() => {
          this._kissLoadingBarService.hide();
        });
    }
  }

  resendCode() {
    this.authService
      .sendResetCode(this.form.getRawValue())
      .subscribe({
        next: (res: any) => {
          this.toastr.success(null, this._translatePipe.transform('notifs.codeResentSuccess'));
          this.submitted = false;
        },
        error: () => {
          this.form.controls['userName'].setErrors({ incorrect: true });
        },
      })
      .add(() => {
        this._kissLoadingBarService.hide();
      });
  }
  submitCode() {
    this.submitted = true;
    if (this.secondForm.invalid) {
      const toastNotif = this._translatePipe.transform('notifs.allFieldsRequired');
      this.toastr.info(null, toastNotif);
    } else {
      this.step = 'third';
      this.lastForm = this.createLastForm();
    }
  }
  /*  submitLastForm() {
    this.submitted = true;
    if (this.form.invalid) {
      const toastNotif = this._translatePipe.transform('notifs.allFieldsRequired');
      this.toastr.info(null, toastNotif);
    } else {
      this.authService
        .resetPassword(this.lastForm.getRawValue())
        .subscribe({
          next: (res: any) => {
            this.toastr.success(null, 'Password successfully changed !');

            this._router.navigate(['login']);
          },
          error: () => {
            this.toastr.error(null, 'Something went wrong !');
          },
        })
        .add(() => {
          this._kissLoadingBarService.hide();
        });
    }
  }*/

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }
  onTogglePass(elPass: any) {
    elPass.type = elPass.type === 'password' ? 'text' : 'password';
  }
  submitLastForm() {
    if (this.form.invalid) return;
    this.config.data.onSendEmail(this.lastForm.getRawValue());
  }
}
