<div
  *ngFor="let overlayRef of keysToArr; let i = index"
  class="kiss-overlay__container kiss-overlay-{{ i }}"
  [ngClass]="[
    overlayRef.info?.fixedContainer ? 'kiss-overlay__fixed-container' : '',
    overlayRef.info.containerClass
  ]"
  (click)="onContainerClicked($event, itemContainer, overlayRef)"
>
  <!-- ITEM CONTAINER-->
  <div class="kiss-overlay__item-container" #itemContainer>
    <kiss-overlay-item [overlayData]="overlays.get(overlayRef)"></kiss-overlay-item>
  </div>
  <!-- !ITEM CONTAINER-->
</div>
