import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Categories } from 'app/shared/models/category.model';
import { environment } from 'environments/environment';
import { BehaviorSubject, tap } from 'rxjs';

const API_CATEGORIES = `${environment.apiShop}/categories`;

@Injectable({ providedIn: 'root' })
export class CategoryService {
  private readonly storageName = 'categoryInfo';
  onCategoryChange: BehaviorSubject<Categories>;

  constructor(private _http: HttpClient) {
    const shop = localStorage.getItem(this.storageName)
      ? JSON.parse(localStorage.getItem(this.storageName))
      : undefined;

    this.onCategoryChange = new BehaviorSubject(shop);
  }

  saveCategories(data: Categories) {
    localStorage.setItem(this.storageName, JSON.stringify(data));
    this.onCategoryChange.next(data);
  }

  getCategoryData(): Categories {
    return localStorage.getItem(this.storageName)
      ? JSON.parse(localStorage.getItem(this.storageName))
      : undefined;
  }

  getCategoryApi(data: { shopId: number }) {
    let params = new HttpParams();
    if (data.shopId) {
      params = params.append('shopId', data.shopId);
    }

    return this._http.get(API_CATEGORIES, { params }).pipe(
      tap((response: Categories) => {
        this.saveCategories(response);
      })
    );
  }

  clearCategoryData() {
    localStorage.removeItem(this.storageName);
    this.onCategoryChange.next(undefined);
  }
}
