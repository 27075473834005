const DEFAULT_SETTINGS = {
  enabled: true,
  generateYears: 15,
};

export class KissDatepickerYearSettings {
  enabled: boolean;
  generateYears: number;

  constructor(data: any) {
    data = data || {};

    this.enabled = data.enabled ?? DEFAULT_SETTINGS.enabled;
    this.generateYears = data.generateYears || DEFAULT_SETTINGS.generateYears;
  }
}
