import { Injectable } from '@angular/core';
import * as uuid from 'uuid';

@Injectable({ providedIn: 'root' })
export class DeviceService {
  private readonly prefix = 'web.shop-admin.';
  private readonly storageName = 'device_id';

  /**
   * Technically we cannot create a device Id for Web Apps like for mobile. Except using crypto
   * @returns
   */
  generateUUIDv4(): string {
    let generatedId: string | undefined = this.getSavedUUID();

    if (!generatedId) {
      generatedId = this.prefix + uuid.v4();
      this.saveUUID(generatedId);
    }

    return generatedId;
  }

  getSavedUUID(): string {
    return localStorage.getItem(this.storageName);
  }

  saveUUID(data: string): void {
    localStorage.setItem(this.storageName, data);
  }
}
