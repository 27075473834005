import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'app/shared/auth/authentication.service';
import { Notifications } from 'app/shared/models/notifications';
import { NotificationService } from 'app/shared/services/notification/notification.service';
import { ShopService } from 'app/shared/services/shop/shop.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'ooblee-toolbar-notifications',
  templateUrl: './toolbar-notifications.component.html',
  styleUrls: ['./toolbar-notifications.component.scss'],
})
export class ToolbarNotificationsComponent implements OnDestroy, AfterViewInit {
  notifications: Notifications;
  private _intervalRef: any;
  private _interval = 60000; //Minute

  private _destroy: Subject<void> = new Subject();

  @ViewChild('notif') notifRef: ElementRef;

  constructor(
    private _notificationsService: NotificationService,
    private _shopService: ShopService,
    private _authService: AuthenticationService,
    private _router: Router
  ) {
    this._destroy = new Subject();
  }

  ngAfterViewInit(): void {
    this._notificationsService.onNotificationChange
      .pipe(takeUntil(this._destroy))
      .subscribe((response: Notifications) => {
        if (response && response?.unseenNotifications > this.notifications?.unseenNotifications) {
          this._playAudio();
        }

        this.notifications = response;
      });

    this.getNotificationsOnInterval();
  }

  ngOnDestroy(): void {
    if (this._intervalRef) {
      clearInterval(this._intervalRef);
    }

    this._destroy.next();
    this._destroy.unsubscribe();
  }

  getNotificationsOnInterval(): void {
    this.getNotifications();

    this._intervalRef = setInterval(() => {
      this.getNotifications();
    }, this._interval);
  }

  getNotifications(): void {
    const isAuthenticated = this._authService.isAuthenticated();
    if (!isAuthenticated) return;

    const id = this._shopService.getShopData()?.id;
    this._notificationsService
      .getNotifications({ shopId: id })
      .pipe(takeUntil(this._destroy))
      .subscribe();
  }

  onGoToMessages(): void {
    this._router.navigate(['/order-management/messages']);
  }

  private _playAudio(): void {
    const audio = new Audio();
    audio.src = 'assets/audio/notification_sound.wav';
    audio.play();
  }

  onGoToNotifications(): void {
    this._router.navigate(['/order-management/notifications']);
  }
}
