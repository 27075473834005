<div class="popup">
  <div
    style="
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 33px;
    "
  >
    <p class="title">How can we help?</p>
    <img
      src="assets/kiss-sidebar/close.svg"
      (click)="onReject()"
      style="cursor: pointer"
    />
  </div>
  <p class="text">
    We’d like to help. Please describe the issues you’re having with as much
    details as possible and we'll reach out shortly.
  </p>

  <input class="input-name" placeholder="Your Subject" />

  <textarea
    class="input-name description"
    placeholder="Your Message"
  ></textarea>
  <div class="actions">
    <button class="btn__tertiary" (click)="onConfirm()">Cancel</button>
    <button class="btn__stroked-brown" (click)="onReject()">Send</button>
  </div>
</div>
