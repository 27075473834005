import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { KissNavigationModule } from '@kiss/components/common/kiss-navigation/kiss-navigation.module';
import { KissSidebarModule } from '@kiss/components/common/kiss-sidebar/kiss-sidebar.module';
import { KissNavigationSidebarComponent } from './kiss-navigation-sidebar.component';
import { KissDialogService } from '../../../common';
import { SuportService } from 'app/shared/components/support/suport.service';
import { ShopSelectModule } from 'app/shared/components/shop-select/shop-select.module';
import { TranslateModule } from 'app/shared/services/translate/translate.module';

@NgModule({
  declarations: [KissNavigationSidebarComponent],
  imports: [
    RouterModule,
    CommonModule,
    KissNavigationModule,
    KissSidebarModule,
    ShopSelectModule,
    TranslateModule,
  ],
  exports: [KissNavigationSidebarComponent],
  providers: [KissDialogService, SuportService],
})
export class KissNavigationSidebarModule {}
