import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { KissModule } from '@kiss';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { KissSvgIconModule } from '@kiss/components/common';
import { environment } from '../environments/environment';
import { AuthInterceptorService } from './shared/auth/auth.interceptor.service';
import { ErrorInterceptor } from './shared/interceptors/error.interceptor';
import { PromptModule } from './shared/components/prompt/prompt.module';
import { CustomToastComponent } from './shared/components/custom-toast/custom-toast.component';
import { TranslateModule } from './shared/services/translate/translate.module';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { KissSplashScreenModule } from '@kiss/components/common/kiss-splash-screen/kiss-splash-screen.module';
//import { RowSelectDirective } from './shared/directives/row-select.directive';

@NgModule({
  declarations: [AppComponent, CustomToastComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    KissModule,
    KissSplashScreenModule,
    KissSvgIconModule.forRoot({
      spriteUrl: environment.spriteUrl,
    }),
    TranslateModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ToastrModule.forRoot({
      toastComponent: CustomToastComponent,
      positionClass: 'toast-bottom-center',
      progressBar: true,
      progressAnimation: 'decreasing',
    }),
    PromptModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
  ],
  providers: [
    Title,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    //StripeService,
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
