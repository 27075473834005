import { NgModule } from '@angular/core';
import { KissDialogModule, KissSvgIconModule } from '@kiss/components/common';
import { PromptService } from './prompt.service';
import { ConfirmOrderPromptComponent } from './confirm-order-prompt/confirm-order-prompt.component';
import { CommonModule } from '@angular/common';
import { ConfirmationPromptComponent } from './confirmation-prompt/confirmation-prompt.component';
import {TranslateModule} from "../../services/translate/translate.module";

@NgModule({
  declarations: [ConfirmOrderPromptComponent, ConfirmationPromptComponent],
    imports: [KissDialogModule, KissSvgIconModule, CommonModule, TranslateModule],
  providers: [PromptService],
})
export class PromptModule {}
