import { KissDatepickerSelectionMode } from '../types/kiss-datepicker-selection-mode';
import { KissDatepickerViews } from '../types/kiss-datepicker-views.type';
import { KissDatepickerDaySettings } from './kiss-datepicker-day-settings';
import { KissDatepickerMonthSettings } from './kiss-datepicker-month-settings';
import { KissDatepickerTimeSettings } from './kiss-datepicker-time-settings';
import { KissDatepickerYearSettings } from './kiss-datepicker-year-settings';

const DEFAULT_SETTINGS = {
  initialView: 'day',
  labelFormats: {
    date: 'dd/MM/yyyy',
    time: 'HH:mm:ss',
  },
  header: {
    showMonth: true,
    showYear: true,
    showTime: false,
  },
};

export class KissDatepickerSettings {
  initialView: KissDatepickerViews;
  labelFormats: {
    date: string;
    time: string;
  };
  header: {
    showMonth: boolean;
    showYear: boolean;
    showTime: boolean;
  };

  //day
  day: KissDatepickerDaySettings;
  year: KissDatepickerYearSettings;
  time: KissDatepickerTimeSettings;
  month: KissDatepickerMonthSettings;

  constructor(data?: any) {
    data = data || {};

    this.initialView = data.initialView || DEFAULT_SETTINGS.initialView;
    this.labelFormats = data.labelFormats
      ? { ...DEFAULT_SETTINGS.labelFormats, ...data.labelFormats }
      : DEFAULT_SETTINGS.labelFormats;

    this.header = data.header
      ? { ...DEFAULT_SETTINGS.header, ...data.header }
      : DEFAULT_SETTINGS.header;

    this.day = new KissDatepickerDaySettings(data.day);
    this.year = new KissDatepickerYearSettings(data.year);
    this.time = new KissDatepickerTimeSettings(data.time);
    this.month = new KissDatepickerMonthSettings(data.month);
  }
}
