import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  ActivatedRoute,
  Route,
  UrlSegment,
  CanActivateChild,
  CanLoad,
} from '@angular/router';
import { AuthenticationService } from '../auth/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, map, take } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private router: Router,
    private auth: AuthenticationService,
    private _route: ActivatedRoute,
    private _toastr: ToastrService,
    private _af: AngularFireAuth
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._runGuardCheck(route, state);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._runGuardCheck(route, state);
  }

  canLoad(route: Route, segments: UrlSegment[]) {
    return this._af.authState.pipe(
      take(1),
      map((user) => {
        if (user) {
          return true; // User is authenticated, allow access
        } else {
          const fullPath = segments.reduce((path, currentSegment) => {
            return `${path}/${currentSegment.path}`;
          }, '');

          this.auth.logoutLocally();
          this.router.navigate(['/login'], { queryParams: { returnUrl: fullPath } });
          this._toastr.warning(null, 'Please log in!');
          return false;
        }
      })
    );
  }

  private _runGuardCheck(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._af.authState.pipe(
      take(1),
      map((user) => {
        if (user) {
          return true; // User is authenticated, allow access
        } else {
          let queryParams = {};
          if (this._route.snapshot.children.length === 0) {
            queryParams = { returnUrl: state.url };
          }

          this.auth.logoutLocally();
          this.router.navigate(['/login'], { queryParams });
          this._toastr.warning(null, 'Please log in!');
          return false;
        }
      })
    );
  }
}
