<kiss-select
  [formControl]="control"
  class="ooblee-transparent-select"
  dropdownClass="ooblee-transparent-select"
  [inheritWidth]="false"
  [enableHeaderTemplate]="true"
  (onSelectChange)="onLanguageChange($event)"
>
  <kiss-select-option *ngFor="let item of languages" [value]="item.value">
    <div class="language-option" *kissOptionHeaderTemplate>
      <img class="language-option__icon" [src]="item?.icon" />
      <span class="language-option__label">{{ item.label }}</span>
    </div>

    <div class="language-option">
      <img class="language-option__icon" [src]="item?.icon" />
      <span class="language-option__label">{{ item.label }}</span>
    </div>
  </kiss-select-option>
</kiss-select>
