<ng-container *ngIf="!item.hidden">
  <div class="nav-seperator" *ngIf="item.seperator"></div>

  <div class="nav-group-title" *ngIf="item.title" [ngClass]="item?.classes">
    <span>{{ item.title }}</span>
  </div>

  <div class="nav-group-translation" *ngIf="item.translation" [ngClass]="item?.classes">
    <span>{{ item.translation | translate }}</span>
  </div>

  <div class="group-items">
    <ng-container *ngFor="let child of item.children">
      <!-- NESTED GROUPS -->
      <kiss-navigation-group *ngIf="child?.type === 'group'" [item]="child"></kiss-navigation-group>
      <!-- !NESTED GROUPS -->

      <!-- NESTED ITEMS -->
      <kiss-navigation-item *ngIf="child?.type === 'item'" [item]="child"></kiss-navigation-item>
      <!-- !NESTED ITEMS -->

      <!-- NESTED LISTS -->
      <kiss-navigation-list *ngIf="child?.type === 'list'" [item]="child"></kiss-navigation-list>
      <!-- NESTED LISTS -->
    </ng-container>
  </div>
</ng-container>
