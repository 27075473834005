import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { KissIconPrePostModule } from '@kiss/directives/kiss-icon-pre-post';
import { KissPaginatorResultsDirective } from './directives/kiss-paginator-results.directive';
import { KissPaginatorComponent } from './kiss-paginator.component';

@NgModule({
  declarations: [KissPaginatorComponent, KissPaginatorResultsDirective],
  imports: [CommonModule],
  exports: [
    KissPaginatorComponent,
    KissIconPrePostModule,
    KissPaginatorResultsDirective,
  ],
})
export class KissPaginatorModule {}
