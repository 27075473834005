import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { KissMenuModule, KissSvgIconModule } from '@kiss/components/common';
import { KissBreadcrumbsModule } from '@kiss/components/common/kiss-breadcrumbs/kiss-breadcrumbs.module';
import { KissDividerModule } from '@kiss/components/common/kiss-divider/kiss-divider.module';
import { KissUserAvatarModule } from '@kiss/components/common/kiss-user-avatar/kiss-user-avatar.module';
import { KissMediaObserverService } from '@kiss/services';
import { ToolbarNotificationsModule } from 'app/shared/components/toolbar-notifications/toolbar-notifications.module';
import { KissToolbarComponent } from './kiss-toolbar.component';
import { ToolbarLanguagesModule } from 'app/shared/components/toolbar-languages/toolbar-languages.module';
import {TranslateModule} from "../../../../../app/shared/services/translate/translate.module";

@NgModule({
  declarations: [KissToolbarComponent],
    imports: [
        CommonModule,
        KissBreadcrumbsModule.forRoot({
            icon: {
                type: 'text',
                data: ' - ',
            },
        }),
        KissDividerModule,
        KissUserAvatarModule,
        KissMenuModule,
        KissSvgIconModule,
        ToolbarNotificationsModule,
        ToolbarLanguagesModule,
        TranslateModule,
    ],
  exports: [KissToolbarComponent],
  providers: [KissMediaObserverService],
})
export class KissToolbarModule {}
