import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DISABLE_TOKEN_INTERCEPTOR } from '../helpers/disable-token-interceptor';
import { environment } from 'environments/environment';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { from } from 'rxjs';

const API_USER_CONNECT = `${environment.baseUrl}/api/user/v4/connect`;
const API_USER_PROFILE = `${environment.baseUrl}/api/user/v4/profile`;

@Injectable({
  providedIn: 'root',
})
export class FirebaseAuthService {
  constructor(private http: HttpClient, private _af: AngularFireAuth) {}

  /**
   * App has to send request to /api/user/v3/connect in order to create custom token
   * Attach the current token to Endpoint
   *
   * @param obj
   * @returns
   */
  connect(obj: { deviceId: string; token: string }) {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + obj.token,
    });

    const context = new HttpContext().set(DISABLE_TOKEN_INTERCEPTOR, true);

    const body = {
      deviceId: obj.deviceId,
    };

    return this.http.post(API_USER_CONNECT, body, {
      headers,
      context,
    });
  }
}
