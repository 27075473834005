<button
  class="kiss-datepicker__btn kiss-datepicker__month__item"
  *ngFor="let month of months"
  [ngClass]="{
    'kiss-datepicker__month__item--current': currentMonth === month?.getMonth()
  }"
  (click)="onMonthChange($event, month)"
>
  {{ month | date: 'MMMM' }}
</button>
