import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { KissOverlayComponent } from './kiss-overlay.component';
import { KissOverlayDirective } from './kiss-overlay.directive';
import { KissOverlayItemComponent } from './kiss-overlay-item/kiss-overlay-item.component';

@NgModule({
  declarations: [KissOverlayComponent, KissOverlayDirective, KissOverlayItemComponent],
  imports: [CommonModule],
  exports: [KissOverlayComponent, KissOverlayDirective],
})
export class KissOverlayModule {}
