<div class="header">
  <img class="logo" src="assets/general/logo.svg" routerLink="login" />

  <!-- <div class="language_select" (click)="toggleModal($event)">
    <p class="selected_language">{{ selected.selectedLanguage }}</p>
    <img src="assets/general/arrow_down.svg" class="arrow" *ngIf="!selected.expanded" />
    <img src="assets/general/arrow_up.svg" class="arrow" *ngIf="selected.expanded" />
  </div> -->
  <!-- <div class="language_modal" *ngIf="selected.expanded">
    <div *ngFor="let option of selected.languages; let index = index">
      <div
        class="option"
        [ngClass]="{ noborder: index === selected.languages.length - 1 }"
        (click)="selectLang(option.short)"
      >
        <img [src]="option.path" class="optionImg" />

        <p class="countryName">{{ option.full }}</p>
      </div>
    </div>
  </div> -->
  <ooblee-toolbar-languages></ooblee-toolbar-languages>
</div>
