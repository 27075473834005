import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DISABLE_ERROR_HANDLING } from 'app/shared/helpers/disable-error-handling';
import { Notifications } from 'app/shared/models/notifications';
import { environment } from 'environments/environment';
import { BehaviorSubject, tap } from 'rxjs';

const API_POST_NOTIFICATIONS = `${environment.baseUrl}/api/social/unread-notifications-count-mobileapi`;

@Injectable({ providedIn: 'root' })
export class NotificationService {
  onNotificationChange = new BehaviorSubject<Notifications>(null);

  constructor(private _http: HttpClient) {}

  getNotifications({ shopId }: { shopId: number }) {
    const body = {
      targetName: 'SHOP',
      targetId: shopId,
    };

    const context = new HttpContext().set(DISABLE_ERROR_HANDLING, true);

    return this._http
      .post(API_POST_NOTIFICATIONS, body, {
        context,
      })
      .pipe(
        tap((response: Notifications) => {
          this.onNotificationChange.next(response);
        })
      );
  }

  clearNotifications() {
    this.onNotificationChange.next(null);
  }
}
