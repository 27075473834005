import { NgModule } from '@angular/core';
import { KissSortTagComponent } from './kiss-sort-tag.component';
import { KissSortDirective } from './kiss-sort.directive';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [KissSortDirective, KissSortTagComponent],
  imports: [CommonModule],
  exports: [KissSortDirective, KissSortTagComponent],
})
export class KissSortModule {}
