import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'app/shared/auth/authentication.service';
import { UserService } from 'app/shared/services/user/user.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { KissToolbar } from './types/kiss-toolbar-config.types';
import { DOCUMENT } from '@angular/common';
import { PromptService } from '../../../../../app/shared/components/prompt/prompt.service';
import { ChangePasswordDialogService } from '../../../../../app/shared/components/dialogs/change-password-dialog/change-password-dialog.service';

@Component({
  selector: 'kiss-toolbar',
  templateUrl: './kiss-toolbar.component.html',
  host: {
    class: 'kiss-toolbar',
  },
})
export class KissToolbarComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() settings: KissToolbar;
  switch: boolean = false;
  user: any;
  notificationCopy: any;

  private _unsubscribeAll: Subject<void>;

  constructor(
    private _userService: UserService,
    private _authService: AuthenticationService,
    private _router: Router,
    private _renderer: Renderer2,
    private _toastr: ToastrService,
    private _elRef: ElementRef,
    private _promptService: PromptService,
    private _changePasswordService: ChangePasswordDialogService,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /*
   * On Init
   */

  ngOnInit(): void {
    this.user = this._userService.getUserData();
  }

  ngAfterViewInit(): void {
    this._setToolbarHeightVar();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  onLogout() {
    this._authService.logout().then(() => {
      this._router.navigate(['/login']).then(() => {
        this._toastr.success(null, 'Successfully logged out!');
      });
    });
  }
  onChangePassword() {
    this._changePasswordService.open();
  }

  onEditUser() {
    this._router.navigate(['/user/profile']);
  }

  stopPropagation($event: MouseEvent) {
    $event.stopPropagation();
  }

  private _setToolbarHeightVar() {
    if (!this._elRef?.nativeElement?.offsetHeight || !this._document?.documentElement) return;

    const height = this._elRef.nativeElement.offsetHeight + 'px';

    this._renderer.setProperty(
      this._document.documentElement,
      'style',
      `--kiss-toolbar-height:${height}`
    );
  }
}
