import { Injectable } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class KissLoadingBarService {
  _onVisibilityChange: BehaviorSubject<boolean>;

  constructor(private _router: Router) {
    this._init();
  }

  private _init() {
    this._onVisibilityChange = new BehaviorSubject(false);

    // Subscribe to the router events to show/hide the loading bar
    this._router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
      this.show();
    });

    this._router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationEnd ||
            event instanceof NavigationError ||
            event instanceof NavigationCancel
        )
      )
      .subscribe(() => {
        this.hide();
      });
  }

  show() {
    this._onVisibilityChange.next(true);
  }

  hide() {
    this._onVisibilityChange.next(false);
  }
}
