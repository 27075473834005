import { Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { KissSvgIconSettings } from './kiss-svg-icon-settings.type';
import { KissSvgIconService } from './kiss-svg-icon.service';

@Component({
  selector: 'kiss-svg-icon',
  template: `
    <svg class="kiss-svg-icon__svg">
      <use attr.xlink:href="{{ settings.spriteUrl }}#icon-{{ icon }}"></use>
    </svg>
  `,
  host: {
    class: 'kiss-svg-icon',
  },
})
export class KissSvgIconComponent {
  settings: KissSvgIconSettings;
  class: string;

  private _icon: string;
  @Input() set icon(value: string) {
    this._icon = value;

    if (this._icon) {
      if (this.class) {
        this._renderer.removeClass(this._elRef.nativeElement, this.class);
      }

      this.class = 'kiss-svg-icon-' + value;

      this._renderer.addClass(this._elRef.nativeElement, this.class);
    }
  }

  get icon(): string {
    return this._icon;
  }

  constructor(
    private _kissSvgIconService: KissSvgIconService,
    private _renderer: Renderer2,
    private _elRef: ElementRef
  ) {
    this.settings = this._kissSvgIconService.getSettings();
  }
}
