import { Component, Input } from '@angular/core';
import { KissNavigationItem } from '../types/kiss-navigation-item.types';

@Component({
  selector: 'kiss-navigation-group',
  templateUrl: './kiss-navigation-group.component.html',
  host: {
    class: 'kiss-navigation-group',
  },
})
export class NavigationSidebarComponent {
  @Input() item: KissNavigationItem;
}
