import { Injectable } from '@angular/core';
import { ActivationStart, NavigationCancel, ResolveEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { KISS_LAYOUT_DEFAULT_CONFIG } from '../kiss-layout-default-config';
import { KissLayoutConfig } from '../types/kiss-layout-config';

/**
 * Service that allows use custom layouts that are located inside LAYOUT MODULE
 *
 * USAGE:
 *
 * Include the KissLayoutModule inside the app.module.ts.
 *
 * If you want to force a layout load as it loads the next route you should use example one inside the route,
 * this is usefull when dealing with authorization. You could also call this servie and use `updateLayout` method.
 * 
 * If you decide to change the `layoutType` keep in mind that it might not work with lazy loaded routes and it will cause problems. 
 * Apparently the `router-outlet` causes a problem with this. 
 * Check this link for more info: https://indepth.dev/posts/1235/how-to-reuse-common-layouts-in-angular-using-router-2.
 * 
 * For a fix pick a layout that can have it's settings changed like `vertical-classic` and apply them accordingly, 
 * or make your own layout by checking out how `vertical-classic` was made :).
 * 
 *  Important TYPES
 * 
 * `KissLayoutConfig`
 * 
 * `KissLayouts`
 *
 * @example
 * data: {
      layoutConfig: {
        layoutType: 'vertical-classic',
        verticalClassicConfig: {
          navigationSidebar: {
            enabled: false,
          },
          toolbar: {
            enabled: false,
          },
        },
      },
    },
 */
@Injectable({
  providedIn: 'root',
})
export class KissLayoutConfigService {
  private _defaultConfig: KissLayoutConfig = KISS_LAYOUT_DEFAULT_CONFIG;
  config: KissLayoutConfig;

  /**
   * Observable that listens for config change
   */
  public onConfigChange: BehaviorSubject<KissLayoutConfig>;

  constructor(private _router: Router) {
    this.config = this._defaultConfig;

    this.onConfigChange = new BehaviorSubject<KissLayoutConfig>(this.config);

    this._init();
  }

  /**
   * Initialize the service
   */
  private _init() {
    let forcedLayout: KissLayoutConfig;

    //MIGHT NEED TO BE REWORKED
    this._router.events
      .pipe(
        filter(
          (e) =>
            e instanceof ActivationStart || e instanceof ResolveEnd || e instanceof NavigationCancel
        )
      )
      .subscribe((e: ActivationStart | ResolveEnd | NavigationCancel) => {
        if (e instanceof NavigationCancel) {
          //remove forcedLayout if navigation is canceled
          forcedLayout = undefined;
        }

        if (e instanceof ActivationStart && e.snapshot?.data?.layoutConfig) {
          //get forced layout
          forcedLayout = e.snapshot.data.layoutConfig;
        }

        if (e instanceof ResolveEnd && forcedLayout) {
          //load forced layout and return
          this.onConfigChange.next(forcedLayout);
          forcedLayout = undefined;
        } else if (e instanceof ResolveEnd && !forcedLayout) {
          //load new config
          this.onConfigChange.next(this.config);
          this.config = this._defaultConfig;
        }
      });
  }

  /**
   * Reset the layout to the default config
   */
  resetLayout(): void {
    this.config = this._defaultConfig;
    this.onConfigChange.next(this.config);
  }

  /**
   * Update the layout by doing a deep object merge
   * @param {KissLayoutConfig} layout
   */
  updateLayout(layout: KissLayoutConfig): void {
    this._mergeDeep(this.config, layout);
    this.onConfigChange.next(this.config);
  }
  /**
   * Update the defualt config programatically, does not trigger `onConfigChange`
   * @param {KissLayoutConfig} layout
   */
  updateDefaultConfig(layout: KissLayoutConfig): void {
    this._defaultConfig = layout;
  }

  /**
   * Simple object check.
   * @param item
   * @returns {boolean}
   */
  private _isObject(item) {
    return item && typeof item === 'object' && !Array.isArray(item);
  }

  /**
   * Deep merge two objects. Will cause issues with recursive references.
   * @param target
   * @param ...sources
   */
  private _mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (this._isObject(target) && this._isObject(source)) {
      for (const key in source) {
        if (this._isObject(source[key])) {
          if (!target[key]) Object.assign(target, { [key]: {} });
          this._mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }

    return this._mergeDeep(target, ...sources);
  }
}
