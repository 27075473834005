import {
  AfterContentInit,
  ContentChildren,
  Directive,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  SimpleChanges,
} from '@angular/core';
import { asyncScheduler, mergeAll, scheduled, startWith, Subject, takeUntil } from 'rxjs';
import { KissSortTagComponent, KISS_SORT_TAG } from './kiss-sort-tag.component';

interface KissSortChange {
  id: string;
  direction: 'asc' | 'desc';
}

@Directive({
  selector: '[kissSort]',
})
export class KissSortDirective implements OnDestroy, AfterContentInit, OnChanges {
  @Input('kissSortActive') activeId: string;
  @Input('kissSortDirection') activeDirection: 'asc' | 'desc';
  @Output() onSortChange = new EventEmitter<KissSortChange>();

  private _destroy: Subject<void> = new Subject();
  private _selectedChanged: Subject<void> = new Subject();

  constructor() {}

  @ContentChildren(KISS_SORT_TAG, { descendants: true })
  private _tags: QueryList<KissSortTagComponent>;

  ngAfterContentInit(): void {
    this._selectedChanged.pipe(takeUntil(this._destroy)).subscribe(() => {
      this._updateSortItems({
        id: this.activeId,
        direction: this.activeDirection,
      });
    });

    this._tags.changes.pipe(startWith(''), takeUntil(this._destroy)).subscribe(() => {
      this._listenForStateChange();

      setTimeout(() => {
        // AVOID EXPRESSION CHANGED ERROR
        this._selectedChanged.next();
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['activeId'] || changes['activeDirection']) {
      this._selectedChanged.next();
    }
  }

  /**
   * method that listenens for option click
   */
  private _listenForStateChange() {
    //unsubscribe if it exists
    const changedOrDestroyed = scheduled([this._tags.changes, this._destroy], asyncScheduler).pipe(
      mergeAll()
    );

    //state changes
    const optionsStateChanges = [...this._tags.toArray().map((tag) => tag.onStateChange)];

    //PIPE HAS TO BE IN THIS ORDER TO UNSUBSCRIBE SUCCESSFULLY
    scheduled(optionsStateChanges, asyncScheduler)
      .pipe(mergeAll(), takeUntil(changedOrDestroyed))
      .subscribe((tag: KissSortTagComponent) => {
        this.activeId = tag.id;
        this.activeDirection = tag.activeDirection;

        const data: KissSortChange = {
          id: this.activeId,
          direction: this.activeDirection,
        };

        this._selectedChanged.next();

        this._sortChanged(data);
      });
  }

  private _sortChanged(data: KissSortChange) {
    this.onSortChange.next(data);
  }

  private _updateSortItems(data: KissSortChange) {
    if (!this._tags?.length) return;
    const tagArr = this._tags.toArray();
    const id = data.id || this._tags[0]?.id;

    for (let i = 0; i < tagArr?.length; i++) {
      const tag = tagArr[i];

      // if id exists find the matching one and change other
      if (id === tag.id) {
        tag.activeDirection = data.direction;
      } else {
        tag.activeDirection = undefined;
      }
    }
  }

  ngOnDestroy(): void {
    this._destroy.next();
  }
}
