import { Component, Input } from '@angular/core';

@Component({
  selector: 'ooblee-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
  @Input() size = '80px';
  @Input() thickness = '5px';
  @Input() color = 'red';
  constructor() {}
}
