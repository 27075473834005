import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { AuthenticationService } from '../auth/authentication.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { map, take } from 'rxjs';

@Injectable()
export class UnAuthGuard implements CanActivate, CanActivateChild {
  isFirstTime = true;
  constructor(
    private router: Router,
    private auth: AuthenticationService,
    private _af: AngularFireAuth
  ) {}

  canActivate() {
    return this._runGuardCheck();
  }

  canActivateChild() {
    return this._runGuardCheck();
  }

  private _runGuardCheck() {
    return this._af.authState.pipe(
      take(1),
      map((user) => {
        if (user) {
          this.router.navigate(['/landing']);
          return false;
        } else {
          return true;
        }
      })
    );
  }
}
