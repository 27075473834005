import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KissToggleSwitchComponent } from './kiss-toggle-switch.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [KissToggleSwitchComponent],
  imports: [CommonModule, FormsModule],
  exports: [KissToggleSwitchComponent],
})
export class KissToggleSwitchModule {}
