import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../shared/auth/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { KissLoadingBarService } from '@kiss/components/common';
import { ConfirmedValidator } from 'app/shared/custom-validators/password.validator';
import { TranslatePipe } from 'app/shared/services/translate/translate.pipe';

@Component({
  selector: 'Ooblee-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  form: FormGroup;
  secondForm: FormGroup;
  submitted = false;

  step: 'first' | 'second' | 'third' = 'first';
  alert = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  lastForm: FormGroup;
  constructor(
    private _fb: FormBuilder,
    private _router: Router,
    private authService: AuthenticationService,
    private toastr: ToastrService,
    private _kissLoadingBarService: KissLoadingBarService,
    private _translatePipe: TranslatePipe
  ) {}

  ngOnInit(): void {
    this.form = this.createForm();
    this.secondForm = this.createSecondForm();

    this.detectChange();
  }

  detectChange() {
    this.form
      .get('userName')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((x) => {
        this.submitted = false;
      });
    this.secondForm
      .get('code')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((x) => {
        this.submitted = false;
      });
  }

  createForm() {
    return this._fb.group({
      userName: [
        null,
        [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')],
      ],
    });
  }

  createSecondForm() {
    return this._fb.group({
      code: [null, [Validators.required]],
    });
  }

  submit() {
    this.submitted = true;
    if (this.form.invalid) {
      const toastNotif = this._translatePipe.transform('notifs.allFieldsRequired');
      this.toastr.info(null, toastNotif);
    } else {
      this.authService
        .sendResetCode(this.form.getRawValue())
        .subscribe({
          next: (res: any) => {
            this.step = 'second';
          },
          error: () => {
            this.form.controls['userName'].setErrors({ incorrect: true });
          },
        })
        .add(() => {
          this._kissLoadingBarService.hide();
        });
    }
  }

  resendCode() {
    this.authService
      .sendResetCode(this.form.getRawValue())
      .subscribe({
        next: (res: any) => {
          if (res.result === 11) {
            this.toastr.error(
              null,
              'Oops! It seems you entered the wrong reset code. Please double-check your inbox or spam folder and enter the correct code to proceed.'
            );
          } else if (res.result === 0) {
            this.toastr.success(null, 'Password successfully changed!');
          }
          this.submitted = false;
        },
        error: () => {
          this.form.controls['userName'].setErrors({ incorrect: true });
        },
      })
      .add(() => {
        this._kissLoadingBarService.hide();
      });
  }
  createLastForm() {
    return this._fb.group(
      {
        repeatedPassword: ['', [Validators.required, Validators.minLength(8)]],
        password: ['', [Validators.required, Validators.minLength(8)]],
        userName: [this.form.get('userName').value, [Validators.required]],
        forgotPasswordCode: [this.secondForm.get('code').value, [Validators.required]],
      },
      { validator: ConfirmedValidator('password', 'repeatedPassword') }
    );
  }

  submitLastForm() {
    this.submitted = true;
    if (this.form.invalid) {
      const toastNotif = this._translatePipe.transform('notifs.allFieldsRequired');
      this.toastr.info(null, toastNotif);
    } else {
      this.authService
        .resetPassword(this.lastForm.getRawValue())
        .subscribe({
          next: (res: any) => {
            this.toastr.success(null, 'Password successfully changed !');

            this._router.navigate(['login']);
          },
          error: () => {
            this.toastr.error(null, 'Something went wrong !');
          },
        })
        .add(() => {
          this._kissLoadingBarService.hide();
        });
    }
  }

  submitCode() {
    this.submitted = true;
    if (this.secondForm.invalid) {
      const toastNotif = this._translatePipe.transform('notifs.allFieldsRequired');
      this.toastr.info(null, toastNotif);
    } else {
      this.step = 'third';
      this.lastForm = this.createLastForm();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }

  onTogglePass(elPass: any) {
    elPass.type = elPass.type === 'password' ? 'text' : 'password';
  }
}
