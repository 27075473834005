import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { HeaderComponent } from './header.component';
import { TranslateModule } from 'app/shared/services/translate/translate.module';
import { ToolbarLanguagesModule } from 'app/shared/components/toolbar-languages/toolbar-languages.module';

@NgModule({
  declarations: [HeaderComponent],
  imports: [CommonModule, TranslateModule, RouterModule, ToolbarLanguagesModule],
  providers: [],
  exports: [HeaderComponent],
})
export class HeaderModule {}
