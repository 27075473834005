<div
  class="kiss-layout-wrapper position"
  [ngClass]="{
    'kiss-flex-position__left':
      config.navigationSidebar?.position === 'left' || config.navigationSidebar?.position,
    'kiss-flex-position__right': config.navigationSidebar?.position === 'right'
  }"
>
  <kiss-toolbar *ngIf="config.toolbar?.enable" [settings]="config.toolbar"></kiss-toolbar>
  <!--  NAVIGATION SIDEBAR -->
  <div class="kiss-content-container">
    <kiss-sidebar
      class="main-nav-sidebar"
      [name]="'navBar'"
      [lockedOpen]="true"
      [open]="true"
      [position]="config.navigationSidebar?.position"
      [folded]="!config.navigationSidebar?.expand"
      [foldedWidth]="4.5"
      *ngIf="config.navigationSidebar?.enable"
    >
      <kiss-navigation-sidebar [settings]="config.navigationSidebar"></kiss-navigation-sidebar>
    </kiss-sidebar>
    <!--  !NAVIGATION SIDEBAR -->

    <div
      class="kiss-content-wrapper position"
      [ngClass]="{
        'kiss-flex-position__top': config.toolbar?.position === 'top' || !config.toolbar?.position,
        'kiss-flex-position__bottom': config.toolbar?.position === 'bottom'
      }"
    >
      <!-- CONTENT -->
      <kiss-content></kiss-content>
      <!-- !CONTENT -->
    </div>
  </div>
</div>
