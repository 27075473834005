import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { KissContentModule, KissSidebarModule } from '@kiss/components/common';
import { KissNavigationSidebarModule, KissToolbarModule } from '../../components';
import { LayoutVerticalClassicComponent } from './layout-vertical-classic.component';

@NgModule({
  declarations: [LayoutVerticalClassicComponent],
  imports: [
    CommonModule,
    KissContentModule,
    KissToolbarModule,
    KissNavigationSidebarModule,
    KissSidebarModule,
  ],
  exports: [LayoutVerticalClassicComponent],
})
export class LayoutVerticalClassicModule {}
