import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarNotificationsComponent } from './toolbar-notifications.component';
import { KissSvgIconModule } from '@kiss/components/common';

@NgModule({
  declarations: [ToolbarNotificationsComponent],
  imports: [CommonModule, KissSvgIconModule],
  exports: [ToolbarNotificationsComponent],
})
export class ToolbarNotificationsModule {}
