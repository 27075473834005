import { NgModule } from '@angular/core';
import { KissDirectivesModule } from '@kiss/directives/kiss-directives.module';
import { KissPipesModule } from '@kiss/pipes/kiss-pipes.module';
import { KissGuardsModule } from '@kiss/guards/kiss-guards.module';

@NgModule({
  imports: [KissDirectivesModule, KissPipesModule, KissGuardsModule],
  exports: [KissDirectivesModule, KissPipesModule, KissGuardsModule],
})
export class KissSharedModule {}
