<Ooblee-header></Ooblee-header>

<div class="container">
  <div class="login-container">
    <img class="iphone-mockup" src="assets/Auth/login-image.svg" />

    <form (submit)="submit()" (keyup.enter)="submit()" [formGroup]="form" class="form">
      <p class="title">{{ 'common.hello' | translate }}, {{ shopName }}</p>
      <p>{{ 'placeholders.enterCodeTitle' | translate }}</p>
      <div class="row_password" [ngClass]="{ invalid: alert }">
        <input
          #elPass
          [ngClass]="{ invalid: alert }"
          autocomplete="off"
          class="input-password"
          formControlName="password"
          placeholder="{{ 'placeholders.enterCode' | translate }}"
          type="password"
        />
        <img
          (click)="onTogglePass(elPass)"
          *ngIf="form.get('password').value != '' && elPass.type === 'password'"
          class="absolute_password"
          src="assets/Auth/password-hidden.svg"
        />
        <img
          (click)="onTogglePass(elPass)"
          *ngIf="form.get('password').value != '' && elPass.type === 'text'"
          class="absolute_password"
          src="assets/Auth/openEye.svg"
        />
      </div>

      <!-- <p class="reset" *ngIf="alert">
        {{ 'notifs.errorMsgPassword' | translate }}
        <a class="link" routerLink="forgot-password">{{ 'notifs.resetItNow' | translate }}</a
        >.
      </p> -->
      <div
        (click)="submit()"
        [ngClass]="{
          valid: form.valid
        }"
        class="submit-button"
      >
        {{ 'logIn.login' | translate }}
      </div>
      <div class="row second">
        <!-- <p class="remember" (click)="toggleRemember()">
          <img src="assets/Auth/check.svg" class="checked" *ngIf="rememberMe" />
          <img src="assets/Auth/uncheck.svg" *ngIf="!rememberMe" />
          {{ 'logIn.rememberMe' | translate }}
        </p> -->
        <!-- <p class="forgot" routerLink="/forgot-password">{{ 'logIn.forgotPassword' | translate }}</p> -->
      </div>
      <div class="getapp">
        <p class="text">{{ 'logIn.getAppp' | translate }}</p>
        <div class="store">
          <img src="assets/Auth/google-play-badge.svg" />
          <img src="assets/Auth/appStore.svg" />
        </div>
      </div>
    </form>
  </div>
</div>
<Ooblee-footer></Ooblee-footer>
