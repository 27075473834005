import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  Type,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { kissAnimations } from '@kiss/animations';
import { KissDialogConfig } from './helpers/kiss-dialog-config';
import { KissDialogContent } from './helpers/kiss-dialog-content';
import { KissDialogRef } from './helpers/kiss-dialog-ref';

@Component({
  selector: 'kiss-dialog',
  templateUrl: './kiss-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      .kiss-dialog__content-container {
        width: 100vw;
        height: 100vh;
      }
    `,
  ],
  animations: kissAnimations,
})
export class KissDialogComponent implements OnDestroy, AfterViewInit, OnInit {
  visible = true;

  childComponentType: Type<any>;
  dialogContent: KissDialogContent;

  @ViewChild('container') container: ElementRef;
  @ViewChild('content', { read: ElementRef }) content: ElementRef;

  private _exitOnClickOutsideListener: any;
  private _exitOnEscListener: any;
  constructor(
    private _cdr: ChangeDetectorRef,
    private _ref: KissDialogRef,
    public config: KissDialogConfig,
    private _renderer: Renderer2
  ) {}

  //-------------------------------------
  // Lifecycle hooks
  //-------------------------------------

  /**
   * OnInit
   */
  ngOnInit(): void {
    this.dialogContent = new KissDialogContent(this.childComponentType, this.config);
  }
  /**
   * AfterViewInit
   */
  ngAfterViewInit(): void {
    this._initConfig(this.config);
  }

  /**
   * OnDestroy
   */
  ngOnDestroy(): void {
    this._removeAllClickListeners();
  }

  //-------------------------------------
  // Private methods
  //-------------------------------------

  /**
   * Destroy the dialog reference and remove `kiss-dialog` component from the dom
   */
  private _destroyRef(): void {
    if (this._ref) this._ref.destroy();
  }

  /**
   * Initialize all configuration settings like listeners, css for host etc...
   * @param config
   */
  private _initConfig(config: KissDialogConfig) {
    //EXIT ON CLICK OUTSIDE EVENT
    if (config?.exitOnClickOutside) {
      this._exitOnClickOutsideListener = this._renderer.listen(
        this.container.nativeElement,
        'click',
        (event: Event) => {
          const content = this.content?.nativeElement;

          if (content && !content.contains(event.target)) {
            this._destroyRef();
          }
        }
      );
    }

    //EXIT ON ESCAPE
    if (config?.exitOnEsc) {
      this._exitOnEscListener = this._renderer.listen(
        document,
        'keydown',
        (event: KeyboardEvent) => {
          const content = this.content?.nativeElement;
          const contains = content && content.contains(event.target);

          if (event.key === 'Escape' && contains) {
            this._destroyRef();
          }
        }
      );
    }
  }

  /**
   * Remove all click listeners
   */
  private _removeAllClickListeners() {
    if (this._exitOnClickOutsideListener) this._exitOnClickOutsideListener();
    if (this._exitOnEscListener) this._exitOnEscListener();
  }

  //-------------------------------------
  // Public methods
  //-------------------------------------

  /**
   * Close the dialog ref
   */
  hide(): void {
    if (this._ref) this._ref.close();
  }

  /**
   * Hide the overlay
   */
  close(): void {
    this.visible = false;
    this._cdr.markForCheck();
  }
}
