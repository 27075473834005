import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, HostListener, Inject, Input, OnDestroy, Renderer2 } from '@angular/core';

@Directive({
  selector: '[kissTooltip]',
  host: {
    class: 'kiss-tooltip',
  },
})
export class KissTooltipDirective implements OnDestroy {
  @Input() kissTooltip = '';
  @Input() kissTooltipTimeout: any = 2000;

  @HostListener('mousemove', ['$event']) onMouseMove($event) {
    this.checkMove($event);
  }

  @HostListener('mouseleave') onMouseLeave() {
    this._clearTimeout();
  }

  private _timeout: any;
  private _childRef: Element;
  constructor(private _renderer: Renderer2, private _elRef: ElementRef, @Inject(DOCUMENT) private document: Document) {}

  private checkMove(event) {
    this._clearTimeout();
    const contains = this._elRef.nativeElement === event.target || this._elRef.nativeElement.contains(event.target);
    if (!contains) return;

    this._timeout = setTimeout(() => {
      this._createChild(event);
    }, this.kissTooltipTimeout);
  }

  private _createChild(event) {
    if (!this.kissTooltip) return;

    //create el
    this._childRef = this._renderer.createElement('div');
    this._renderer.addClass(this._childRef, 'kiss-tooltip__tag');

    //add text
    const text = this._renderer.createText(this.kissTooltip || '');
    this._renderer.appendChild(this._childRef, text);

    //append the child to body
    this._renderer.appendChild(this.document.body, this._childRef);

    //calc the position
    const yPos = event.clientY + 10;
    const xPos = event.clientX;

    this._renderer.setStyle(this._childRef, 'top', yPos + 'px');
    this._renderer.setStyle(this._childRef, 'left', xPos + 'px');
    this._renderer.setAttribute(this._childRef, 'tabindex', '-1');
  }

  private _destroyChild() {
    if (!this._childRef) return;
    this._renderer.removeChild(this.document.body, this._childRef);
    this._childRef = null;
  }

  private _clearTimeout() {
    clearTimeout(this._timeout);
    this._destroyChild();
  }

  ngOnDestroy(): void {
    this._clearTimeout();
  }
}
