import { Component, OnInit } from '@angular/core';
import { UserService } from './shared/services/user/user.service';
import { filter, take, takeUntil } from 'rxjs/operators';
import { navigation } from './navigation/navigation';
import { Subject } from 'rxjs';
import { KissNavigationItem, KissNavigationService } from '@kiss/components/common';
import { CacheService } from './shared/services/cache/cache.service';
import { TranslateService } from './shared/services/translate/translate.service';
import { DataValidityService } from './shared/services/data-validity.service';
import { NavigationEnd, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'ooblee';
  navigation: KissNavigationItem[] = [];
  private firstTime = true;
  splashScreen = false;
  private _destroy: Subject<void>;
  constructor(
    private _userService: UserService,
    private _navigationService: KissNavigationService,
    private _cache: CacheService,
    private _translateService: TranslateService,
    private _dataValidityService: DataValidityService,
    private _router: Router,
    private _af: AngularFireAuth
  ) {
    this._destroy = new Subject();
    this._dataValidityService.checkVersion();

    const code = this._translateService.getSavedCode() || this._translateService.defaultLangCode;
    this._translateService.set({ code: code });

    this._loadSplashScreen();
  }

  ngOnInit(): void {
    this._userService
      .userDataChange()
      .pipe(takeUntil(this._destroy))
      .subscribe(() => {
        this.renderNavigation(this._userService.getUserData());
      });
  }

  private renderNavigation(user) {
    this.navigation = user ? navigation : [];

    if (!this.firstTime) {
      this._navigationService.unregister('main');
    }
    this._navigationService.register('main', this.navigation);
    this._navigationService.setCurrentNavigation('main');

    this.firstTime = false;
  }

  private _loadSplashScreen() {
    this.splashScreen = true;

    this._router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        take(1)
      )
      .subscribe(() => {
        this.splashScreen = false;
      });
  }

  ngOnDestroy() {
    this._cache.clear();
    this._destroy.next();
    this._destroy.complete();
  }
}
