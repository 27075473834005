import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CacheService {
  private _cache = new Map<string, { expiresIn: number; value: any }>();
  private readonly CACHE_VALID_MINUTES = 5;

  setItem(key: string, value: any) {
    const data: { expiresIn: number; value: any } = {
      expiresIn: this._addMinutesToCurrentDate(this.CACHE_VALID_MINUTES),
      value,
    };

    this._cache.set(key, data);
  }

  getItem(key: string): { expiresIn: number; value: any } {
    if (!this._cache.has(key)) return;

    return this._cache.get(key);
  }

  getItemValue(key: string): any {
    if (!this._cache.has(key)) return;
    return this._cache.get(key)?.value;
  }

  getValidItem(key: string): { expiresIn: number; value: any } {
    if (!this._cache.has(key)) return;
    const data = this._cache.get(key);

    if (this._isExpired(data.expiresIn)) {
      this._cache.delete(key);
      return undefined;
    }

    return data;
  }

  getValidItemData(key: string): any {
    if (!this._cache.has(key)) return;
    const data = this._cache.get(key);

    if (this._isExpired(data.expiresIn)) {
      this._cache.delete(key);
      return undefined;
    }

    return data?.value;
  }

  deleteItem(key: string) {
    if (!this._cache.has(key)) return;

    this._cache.delete(key);
  }

  clear() {
    this._cache.clear();
  }

  private _addMinutesToCurrentDate(minutes) {
    return new Date().getTime() + minutes * 60000;
  }

  private _isExpired(time: number): boolean {
    const currentTime = new Date().getTime();

    return currentTime > time;
  }
}
