import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { KissNavigationService } from './services/kiss-navigation.service';
import { KissNavigationItem } from './types/kiss-navigation-item.types';
import { TranslateService } from 'app/shared/services/translate/translate.service';

@Component({
  selector: 'kiss-navigation',
  templateUrl: './kiss-navigation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'kiss-navigation',
  },
})
export class KissNavigationComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  navigation: KissNavigationItem[] = [];

  @Input()
  @HostBinding('class.folded')
  folded = false;

  // Private
  private _unsubscribeAll: Subject<void>;

  /**
   *
   * @param {ChangeDetectorRef} _cdr
   * @param {KissNavigationService} _navigationService
   */
  constructor(
    private _navigationService: KissNavigationService,
    private _cdr: ChangeDetectorRef,
    private _translateService: TranslateService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  /**
   * On Init
   */
  ngOnInit(): void {
    // Load the navigation either from the input or from the service
    this.navigation = this.navigation || this._navigationService.getCurrentNavigation();

    // Subscribe to the current navigation changes
    this._navigationService.onNavigationChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        // Load the navigation
        this.navigation = this._navigationService.getCurrentNavigation();

        // Mark for check
        this._cdr.markForCheck();
      });

    // Subscribe to navigation item

    this._navigationService.onNavigationItemsUpdated
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        // Mark for check
        this._cdr.markForCheck();
      });

    this._translateService.onLanguageChange.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      // Mark for check
      this._cdr.markForCheck();
    });
  }

  /**
   * On Changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    //On Folded Change
    if (changes['folded']) {
      this._navigationService.onFoldToggle.next({
        isFolded: this.folded,
        expandActive: false,
      });
    }
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
