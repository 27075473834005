import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { kissAnimations } from '@kiss/animations';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { KissNavigationService } from '../services/kiss-navigation.service';
import { KissNavigationItem } from '../types/kiss-navigation-item.types';

@Component({
  selector: 'kiss-navigation-list',
  templateUrl: './kiss-navigation-list.component.html',
  styles: [
    `
      :host .nav-icon img {
        display: none;
      }

      :host .nav-icon img.active-icon {
        display: block;
      }
    `,
  ],
  host: {
    class: 'kiss-navigation-list',
  },
  animations: kissAnimations,
})
export class KissNavigationListComponent implements OnInit, OnDestroy {
  @Input() item: KissNavigationItem;

  @HostBinding('class.open')
  public isOpen = false;

  @HostBinding('class.active')
  public isActive = false;

  private _isFirstTime = true;
  @HostBinding('@.disabled') animationDisabledOnLoad: boolean = true;

  private _unsubscribeAll: Subject<void>;
  constructor(
    private _router: Router,
    private _cdr: ChangeDetectorRef,
    private _navigationService: KissNavigationService
  ) {
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.preloadAllImages();

    // // Listen for router events
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((event: NavigationEnd) => {
        // Check if the url can be found in
        // one of the children of this item
        this._updateActive(event.urlAfterRedirects);
        if (this._isFirstTime) {
          this.toggleExpand();
          this._isFirstTime = false;
        }

        // Check if the url can be found in
        // one of the children of this item
        if (!this.isUrlInChildren(this.item, this._router.url)) {
          this.collapse();
        }

        if (this.animationDisabledOnLoad) {
          this.animationDisabledOnLoad = false;
        }

        this._cdr.detectChanges();
      });

    // Listen for collapsing of any navigation item
    this._navigationService.onItemCollapsed
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((clickedItem) => {
        if (!clickedItem || !clickedItem.children) return;

        // Check if the clicked item is one
        // of the children of this item
        if (this.isChildrenOf(this.item, clickedItem)) return;

        // Check if the url can be found in
        // one of the children of this item
        if (this.isUrlInChildren(this.item, this._router.url)) return;

        // If the clicked item is not this item, collapse...
        if (this.item !== clickedItem) {
          this.collapse();
        }
      });

    // Check if the url can be found in
    // one of the children of this item
    this._updateActive(this._router.url);
    this.toggleExpand();

    // Subscribe to navigation item
    this._navigationService.onNavigationItemsUpdated
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        // Mark for check
        this._cdr.markForCheck();
      });

    this._navigationService.onFoldToggle
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ isFolded, expandActive }) => {
        if (!isFolded) return;
        this.isOpen = false;
        if (expandActive) {
          this._updateActive(this._router.url);
          this.toggleExpand();
        }

        this._cdr.markForCheck();
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle collapse
   *
   * @param ev
   */
  toggleOpen(ev): void {
    ev.preventDefault();

    this.isOpen = !this.isOpen;

    if (this.isOpen) {
      this._navigateOnOpen();
    }

    // Navigation collapse toggled...
    this._navigationService.onItemCollapsed.next(this.item);
    this._navigationService.onItemCollapseToggled.next({
      item: this.item,
      isOpen: this.isOpen,
    });
  }

  /**
   * Expand the collapsable navigation
   */
  expand(): void {
    if (this.isOpen) {
      return;
    }

    this.isOpen = true;

    // Mark for check
    this._cdr.markForCheck();

    this._navigationService.onItemCollapseToggled.next({
      item: this.item,
      isOpen: this.isOpen,
    });
  }

  /**
   * Collapse the collapsable navigation
   */
  collapse(): void {
    if (!this.isOpen) {
      return;
    }

    this.isOpen = false;

    // Mark for check
    this._cdr.markForCheck();

    this._navigationService.onItemCollapseToggled.next({
      item: this.item,
      isOpen: this.isOpen,
    });
  }

  /**
   * Check if the given parent has the
   * given item in one of its children
   *
   * @param parent
   * @param item
   * @returns {boolean}
   */
  isChildrenOf(parent, item): boolean {
    const children = parent.children;

    if (!children) return false;

    if (children.indexOf(item) > -1) return true;

    for (const child of children) {
      if (child.children && this.isChildrenOf(child, item)) return true;
    }

    return false;
  }

  /**
   * Check if the given url can be found
   * in one of the given parent's children
   *
   * @param parent
   * @param url
   * @returns {boolean}
   */
  isUrlInChildren(parent, url): boolean {
    const children = parent.children;

    if (!children) {
      return false;
    }

    for (const child of children) {
      if (child.children && this.isUrlInChildren(child, url)) {
        return true;
      }

      if (child.url === url || url.includes(child.url)) {
        return true;
      }
    }

    return false;
  }

  /**
   * @param link
   * @returns {void}
   */
  preloadIconImg(link: any): void {
    const image = new Image();
    image.src = link;
  }

  preloadAllImages() {
    if (this.item?.icon?.imgSrc) this.preloadIconImg(this.item?.icon?.imgSrc);
    if (this.item?.icon?.imgSrcActive)
      this.preloadIconImg(this.item?.icon?.imgSrcActive);
  }

  toggleExpand() {
    if (this.isActive) {
      this.expand();
    } else {
      this.collapse();
    }
  }

  private _updateActive(url) {
    // Check if the url can be found in
    // one of the children of this item
    this.isActive = this.isUrlInChildren(this.item, url);
  }

  private _navigateOnOpen() {
    if (!this.item.navigateOnOpen || this.isActive) return;
    this._router.navigate([this.item.navigateOnOpen]);
  }
}
