import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { KissDatepickerTime } from './kiss-datepicker-time';
import { KissDatepickerSelectionMode } from '../types/kiss-datepicker-selection-mode';
import { KissDatepickerTimestamp } from '../types/kiss-datepicker-timestamp.type';
import { KissDatepickerTimeSettings } from '../kiss-datepicker-settings/kiss-datepicker-time-settings';
@Component({
  selector: 'kiss-datepicker-time',
  templateUrl: './kiss-datepicker-time.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'kiss-datepicker__time',
  },
})
export class KissDatepickerTimeComponent {
  form: FormArray;
  currentDate: Date;
  min = 0;
  maxMinutes = 59;
  maxSeconds = 59;
  maxHours = 23;
  maxMilliseconds = 999;
  standardPadding = '00';
  millisecondPadding = '000';
  value: KissDatepickerTimestamp[];
  selectionMode: KissDatepickerSelectionMode;

  private _fieldValidators = (min: number, max: number) => [
    Validators.required,
    Validators.pattern('^[0-9]*$'),
    Validators.min(min),
    Validators.max(max),
  ];

  @Input() set settings(settings: KissDatepickerTime) {
    this.selectionMode = settings.selectionMode;
    this.value = settings.value;

    this.form = this._createForm();
  }

  @Input('kissDatepickerTimeSettings') timeSettings: KissDatepickerTimeSettings;

  @Output() onChange = new EventEmitter();

  constructor(private _fb: FormBuilder) {}

  private _createForm() {
    return this._fb.array(this.value?.map((item) => this._createGroup(item)) || []);
  }

  private _createGroup(data: KissDatepickerTimestamp) {
    return this._fb.group({
      hours: [
        this.timeSettings.showHours ? this.padLeft(+data.hours, this.standardPadding) : '00',
        this._fieldValidators(this.min, this.maxHours),
      ],
      minutes: [
        this.timeSettings.showMinutes ? this.padLeft(+data.minutes, this.standardPadding) : '00',
        this._fieldValidators(this.min, this.maxMinutes),
      ],
      seconds: [
        this.timeSettings.showSeconds ? this.padLeft(+data.seconds, this.standardPadding) : '00',
        this._fieldValidators(this.min, this.maxSeconds),
      ],
      milliseconds: [
        this.timeSettings.showMilliseconds
          ? this.padLeft(+data.milliseconds, this.millisecondPadding)
          : '000',
        this._fieldValidators(this.min, this.maxMilliseconds),
      ],
    });
  }

  /**
   * Increase form control value
   * @param controlName
   */
  next(controlName: string, index: number) {
    const control = this.form['controls'][index].get(controlName);
    const prevValue = parseInt(control?.value);
    const newValue = prevValue || prevValue == 0 ? prevValue + 1 : 0;

    this._updateControlValue(control, controlName, newValue);
  }

  /**
   * Reduce form control value
   * @param controlName
   */
  prev(controlName: string, index: number) {
    const control = this.form['controls'][index].get(controlName);
    const prevValue = parseInt(control?.value);
    const newValue = prevValue || prevValue == 0 ? prevValue - 1 : 0;

    this._updateControlValue(control, controlName, newValue);
  }

  private _updateControlValue(control: any, name: string, value: number) {
    const formattedValue = this.updateFieldFormat(name, value);
    control?.setValue(formattedValue);
    control?.markAsDirty();
    this.updateTimestamp();
  }

  updateTimestamp() {
    if (!this.form.valid) return;
    const postModel = this.form.value;
    this.onChange.next(postModel);
  }

  /**
   * Set field by name and reset it if it's over the threshold
   * @param name
   * @param value
   */
  updateFieldFormat(name: string, value: number): string {
    let formattedValue: any = value || 0;
    let max: number;
    let padding = this.standardPadding;

    switch (name) {
      case 'minutes':
        max = this.maxMinutes;
        padding = this.standardPadding;
        break;
      case 'seconds':
        max = this.maxSeconds;
        padding = this.standardPadding;
        break;
      case 'milliseconds':
        max = this.maxMilliseconds;
        padding = this.millisecondPadding;
        break;
      default:
        max = this.maxHours;
        padding = this.standardPadding;
        break;
    }

    if (formattedValue > max) {
      formattedValue = this.min;
    } else if (formattedValue < this.min) {
      formattedValue = max;
    }

    return this.padLeft(formattedValue, padding);
  }

  padLeft(text: number, paddingValue: string): string {
    return String(paddingValue + text).slice(-paddingValue.length);
  }
}
