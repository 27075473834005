<div class="default-dialog__container">
  <div class="default-dialog__header">
    <button class="ooblee-btn default-dialog__header__close" (click)="onReject()">
      <kiss-svg-icon icon="close"></kiss-svg-icon>
    </button>
  </div>

  <div class="default-dialog__body">
    <div class="default-dialog__body__message" [innerHTML]="message"></div>
  </div>
  <div class="default-dialog__footer">
    <button
      class="ooblee-btn__stroked ooblee-btn__stroked--reject ooblee-btn__stroked--reject--hover"
      (click)="onReject()"
    >
      {{ btnData.reject }}
    </button>
    <button
      class="ooblee-btn__stroked ooblee-btn__stroked--confirm ooblee-btn__stroked--confirm-order--hover"
      (click)="onConfirm()"
    >
      {{ btnData.confirm }}
    </button>
  </div>
</div>
