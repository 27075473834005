<ng-template
  kissOverlay
  [kissOverlayContainerClass]="config?.dialogOverlayClass"
  [kissOverlayOpen]="visible"
>
  <!-- DIALOG -->
  <div class="kiss-dialog" [ngClass]="[config?.dialogClass || '']">
    <div
      class="kiss-dialog__content-container"
      #container
      [ngClass]="[config?.dialogContainerClass || '']"
    >
      <!-- AVOIDING NESTING TEMPLATE ISSUES -->
      <kiss-dialog-content #content [content]="dialogContent" @zoomIn></kiss-dialog-content>
      <!-- !AVOIDING NESTING TEMPLATE ISSUES -->
    </div>
  </div>
  <!-- !DIALOG -->
</ng-template>
