<div class="default-dialog__container">
  <div class="default-dialog__header">
    <button class="ooblee-btn default-dialog__header__close" (click)="onClose()">
      <kiss-svg-icon icon="close"></kiss-svg-icon>
    </button>
  </div>

  <div class="default-dialog__body">
    <div class="default-dialog__body__message" [innerHTML]="message"></div>
    <div class="default-dialog__body__submessage" *ngIf="type === 'completed'">

    </div>
  </div>
  <div class="default-dialog__footer">
    <div class="default-dialog__footer__cancel" (click)="onClose()"> {{ 'common.cancel' | translate }}</div>
    <button
      (click)="onReject()"
      *ngIf="type === 'reject'"
      class="ooblee-btn__stroked ooblee-btn__stroked--reject ooblee-btn__stroked--reject--hover"
    >
      <kiss-svg-icon icon="close" class="ooblee-btn__icon ooblee-btn__icon--pre"></kiss-svg-icon>
      {{ 'orderDialog.rejectOrder' | translate }}
    </button>
    <button
      (click)="onConfirm()"
      *ngIf="type === 'confirm'"
      class="ooblee-btn__stroked ooblee-btn__stroked--confirm ooblee-btn__stroked--confirm-order--hover"
    >
      <kiss-svg-icon icon="check" class="ooblee-btn__icon ooblee-btn__icon--pre"></kiss-svg-icon>
      {{ 'orderDialog.confirmOrder' | translate }}
    </button>
    <button
      (click)="onComplete()"
      *ngIf="type === 'completed'"
      class="ooblee-btn__stroked ooblee-btn__stroked--confirm ooblee-btn__stroked--confirm--hover"
    >
      <kiss-svg-icon icon="check" class="ooblee-btn__icon ooblee-btn__icon--pre"></kiss-svg-icon>
      {{ 'orderList.markAsCompleted' | translate }}
    </button>
  </div>
</div>
