import { Component } from '@angular/core';
import { OrderListRequest } from 'app/shared/models/orders.model';
import { KissDialogConfig, KissDialogRef } from '../../../../../@kiss/components/common';

@Component({
  selector: 'ooblee-confirm-order-prompt',
  templateUrl: './confirm-order-prompt.component.html',
  styleUrls: ['./confirm-order-prompt.component.scss'],
})
export class ConfirmOrderPromptComponent {
  order: OrderListRequest['result']['orders'][0];

  public message: string;
  type: string;
  constructor(private ref: KissDialogRef, private config: KissDialogConfig) {
    this.message = this.config.data.message;
    this.type = this.config.data.type;
    this.order = this.config.data.orderData;
  }

  onClose(): void {
    this.ref.destroy();
  }
  onReject(): void {
    this.config.data.onReject(this.order);
  }

  onConfirm(): void {
    this.config.data.onConfirm(this.order);
  }
  onComplete(): void {
    this.config.data.onComplete(this.order);
  }
}
