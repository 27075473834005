import { Directive, IterableChanges, IterableDiffer, IterableDiffers, OnChanges, SimpleChanges, TemplateRef } from "@angular/core";

@Directive()
export class BaseKissRowDirective implements OnChanges {
  protected _columns = [];
  set columns(value: string[]) {
    this._columns = value;
  }

  get columns(): string[] {
    return this._columns;
  }

  /**
   * Function that should return true if this row template should be used for the provided index
   * and row data. If left undefined, this row will be considered the default row template to use
   * when no other when functions return true for the data.
   * For every row, there must be at least one when function that passes or an undefined to default.
   */
  when: (index: number, rowData: any) => boolean;

  /** Differ used to check if any changes were made to the columns. */
  protected _columnsDiffer: IterableDiffer<any>;
  constructor(public template: TemplateRef<any>, protected _differs: IterableDiffers) {}

  ngOnChanges(changes: SimpleChanges): void {
    // Create a new columns differ if one does not yet exist. Initialize it based on initial value
    // of the columns property or an empty array if none is provided.
    if (!this._columnsDiffer) {
      const columns = (changes["columns"] && changes["columns"].currentValue) || [];
      this._columnsDiffer = this._differs.find(columns).create();
      this._columnsDiffer.diff(columns);
    }
  }

  /**
   * Returns the difference between the current columns and the columns from the last diff, or null
   * if there is no difference.
   */
  getColumnsDiff(): IterableChanges<any> | null {
    return this._columnsDiffer.diff(this.columns);
  }
}
