import { ChangeDetectionStrategy, Component, OnDestroy, ViewChild } from "@angular/core";
import { KissCellOutletDirective } from "../kiss-cell/kiss-cell-outlet.directive";

@Component({
  selector: "kiss-row",
  template: `<ng-container kissCellOutlet></ng-container>`,
  host: {
    class: "kiss-row",
    role: "row"
  },
  changeDetection: ChangeDetectionStrategy.Default
})
export class KissRowComponent implements OnDestroy {
  @ViewChild(KissCellOutletDirective, { static: true }) cellOutlet: KissCellOutletDirective;

  constructor() {}

  ngOnDestroy(): void {
    this.cellOutlet._viewContainer.clear();
  }
}
