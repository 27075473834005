import { Component, Input } from '@angular/core';

@Component({
  selector: 'kiss-loader',
  templateUrl: './kiss-loader.component.html',
  host: {
    class: 'kiss-loader',
  },
})
export class KissLoaderComponent {
  @Input() type: 'circle' = 'circle';

  @Input() size: string = '80px';

  constructor() {}
}
