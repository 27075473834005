import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KissDialogRef, KissDialogService, KissLoadingBarService } from '@kiss/components/common';
import { ShopService } from 'app/shared/services/shop/shop.service';
import { environment } from 'environments/environment';
import { ChangePasswordDialogComponent } from './change-password-dialog.component';
import {AuthenticationService} from "../../../auth/authentication.service";
import {ToastrService} from "ngx-toastr";
import {TranslatePipe} from "../../../services/translate/translate.pipe";

@Injectable()
export class ChangePasswordDialogService {
  private _dialogRef: KissDialogRef;

  constructor(
    private _kissDialogService: KissDialogService,
    private _kissLoadingBar: KissLoadingBarService,
    private authService: AuthenticationService,
    private toastr: ToastrService,
    private _translatePipe: TranslatePipe
  ) {

  }

  open(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this._dialogRef = this._kissDialogService.open(ChangePasswordDialogComponent, {
        dialogClass: 'default-dialog',
        exitOnEsc: true,
        data: {
          onSendEmail: (data: any): void => {
            this._kissLoadingBar.show();
            this.authService.resetPassword(data)
              .subscribe({
                next: (res: any) => {
                  if(res.result === 11) {
                    this.toastr.error(null, this._translatePipe.transform('passwordForgot.codeMismatch'));
                  } else if(res.result === 0) {
                    this.toastr.success(null, this._translatePipe.transform('notifs.passwordChangedSuccess'));
                  }
                  this.destroy();
                },
                error: () => {
                  this.toastr.error(null, this._translatePipe.transform('notifs.error'));
                },
              })
              .add(() => {
                this._kissLoadingBar.hide();
              });

          },
          destroyRef: () => {
            this.destroy();
            resolve(false);
          },
        },
      });
    });
  }

  destroy() {
    if (!this._dialogRef) return;
    this._dialogRef.destroy();
  }

}
