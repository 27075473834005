import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'kiss-user-avatar',
  templateUrl: './kiss-user-avatar.component.html',
  host: {
    class: 'kiss-user-avatar kiss-user-avatar__colors',
  },
})
export class KissUserAvatarComponent {
  @Input() @HostBinding('style.background-color') bgColor: string;
  @Input() @HostBinding('style.color') textColor: string;

  private _firstName = '';
  private _lastName = '';

  @Input() set firstName(value: string) {
    this._firstName = value ? value[0] : '';
  }

  get firstName(): string {
    return this._firstName || '';
  }

  @Input() set lastName(value: string) {
    this._lastName = value ? value[0] : '';
  }
  get lastName(): string {
    return this._lastName || '';
  }

  @Input() set fullName(value: string) {
    const name = value?.split(' ') || [];
    this._firstName = name[0];
    this._lastName = name[1];
  }
}
