import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, Optional } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { KissBreadcrumbService } from "./services/kiss-breadcrumbs.service";
import { KissBreadcrumb } from "./kiss-breadcrumb";
import { KissBreadcrumbsSettings } from "./kiss-breadcrumb-settings.type";
import { KissBreadcrumbsSettingsService } from "./services/kiss-breadcrumbs-settings.service";

@Component({
  selector: "kiss-breadcrumbs",
  templateUrl: "./kiss-breadcrumbs.component.html",
  host: {
    class: "kiss-breadcrumbs"
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KissBreadcrumbsComponent implements OnInit, OnDestroy {
  breadcrumbs: KissBreadcrumb[] = [];
  settings: KissBreadcrumbsSettings;

  private _unsubscribeAll: Subject<void>;
  constructor(
    private readonly _kissBreadcrumbService: KissBreadcrumbService,
    //not injected if no settings are passed
    @Optional() private _kissBreadcrumbsSettingsService: KissBreadcrumbsSettingsService,
    private _cdr: ChangeDetectorRef,
    private _router: Router
  ) {
    this._unsubscribeAll = new Subject();

    if (this._kissBreadcrumbsSettingsService) {
      this.settings = this._kissBreadcrumbsSettingsService.getSettings();
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._kissBreadcrumbService.breadcrumbs$.subscribe((data) => {
      this.breadcrumbs = data?.length ? data : [];
      this._cdr.markForCheck();
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  onClick(breadcrumb: KissBreadcrumb) {
    const url = breadcrumb.redirectUrl ? breadcrumb.redirectUrl : breadcrumb.url;
    if (breadcrumb.useRouteQueryParams) {
      this._router.navigate([url], { queryParams: breadcrumb.routeQueryParams });
    } else {
      this._router.navigate([url], { queryParams: breadcrumb.queryParams });
    }
  }

  checkRouteActive(breadcrumb: KissBreadcrumb) {
    if (breadcrumb.exactMatch) {
      return breadcrumb.url == this._router.url.split("?")[0];
    }

    return this._router.url.includes(breadcrumb.url);
  }
}
