import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { KissDatepickerYearSettings } from '../kiss-datepicker-settings/kiss-datepicker-year-settings';
import { KissDatepickerYear } from './kiss-datepicker-year';

@Component({
  selector: 'kiss-datepicker-year',
  templateUrl: './kiss-datepicker-year.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'kiss-datepicker__year',
  },
})
export class KissDatepickerYearComponent {
  years: KissDatepickerYear[] = [];
  currentYear: number;

  @Input('kissDatepickerYearSettings') yearSettings: KissDatepickerYearSettings;

  private _viewInfoDate: Date;
  @Input() set viewInfoDate(value: any) {
    this._viewInfoDate = value;
    this.currentYear = this._viewInfoDate.getFullYear();
  }

  private _viewDate: Date;
  @Input() set viewDate(value: any) {
    this._viewDate = value;
    this.years = this._generateYears(this._viewDate);
  }

  get viewDate() {
    return this._viewDate;
  }

  @Output() onChange = new EventEmitter();

  constructor() {}

  private _generateYears(date: Date) {
    const year = date.getFullYear() || undefined;
    const tmpYearDate = new Date(date.getTime()).setDate(1);
    const tmpYear = new Date(tmpYearDate).setHours(0, 0, 0, 0);
    const tmpDate = new Date(tmpYear);

    const newYears = [];

    for (let i = this.yearSettings.generateYears; i >= 0; i--) {
      if (year - i <= 0 || year >= 9999) break;

      const newYear = tmpDate.setFullYear(year - i);
      const datepickerYear = new Date(newYear);

      newYears.push(datepickerYear);
    }

    return newYears;
  }

  onYearChange(event, date: Date) {
    event.stopPropagation();
    this.onChange.next(date);
  }
}
