import { Component, OnDestroy } from '@angular/core';
import { KissDialogConfig } from '@kiss/components/common/kiss-dialog/helpers/kiss-dialog-config';
import { KissDialogRef } from '@kiss/components/common/kiss-dialog/helpers/kiss-dialog-ref';
import {SuportService} from "../suport.service";

@Component({
  selector: 'app-confirmation-prompt',
  templateUrl: './suport-prompt.component.html',
  styleUrls: ['./suport-prompt.component.scss'],
})
export class SuportPromptComponent implements OnDestroy {
  public message: string = '';
  constructor(private ref: KissDialogRef, private config: KissDialogConfig, private supportService: SuportService) {
    this.message = this.config.data.message;
  }

  onConfirm() {
    this.config.data.onAction(true);
    this.ref.destroy();
  }

  onReject() {
    this.config.data.onAction(false);
    this.ref.destroy();
  }

  ngOnDestroy(): void {
    if (this.ref) {
      this.config.data.onAction(false);
      this.ref.destroy();
    }
  }
}
