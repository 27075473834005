export const I18N_LANGUAGES = [
  {
    label: 'English',
    icon: 'assets/countries-flag/gb.svg',
    value: 'en',
  },
  {
    label: 'Crnogorski',
    icon: 'assets/countries-flag/me.svg',
    value: 'me',
  },
  {
    label: 'Hrvatski',
    icon: 'assets/countries-flag/hr.svg',
    value: 'hr',
  },
  {
    label: 'Deutsch',
    icon: 'assets/countries-flag/de.svg',
    value: 'de',
  },
];
