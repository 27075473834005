import { EnvConfig } from './environment.config';

const URL = 'https://apicloud-test.ooblee.me';

export const environment = {
  production: false,
  appVersion: EnvConfig.APP_VERSION,
  apiVersion: EnvConfig.API_VERSION,
  langVersion: EnvConfig.LANG_VERSION,
  baseUrl: URL,
  api: `${URL}/api`,
  apiShop: `${URL}/api/shop`,
  cdn: EnvConfig.CDN,
  spriteUrl: EnvConfig.SPRITE_URL,
  name: 'DEV',
  areaCode: '+49',
  countryCode: 'DEU',
  countryCodeShort: 'DE',
  defaultLanguage: 'en',
  defaultCity: 1,
  links: {
    terms: 'https://ooblee.de/terms-and-conditions/',
    privacyPolicy: 'https://ooblee.de/privacy-policy/',
    impressum: 'https://ooblee.de/impressum/',
    shopGenerator: 'https://shop-generator.apicloud-test.ooblee.me',
  },
  stripe: {
    enabled: true,
    connect: EnvConfig.STRIPE_CONNECT,
  },
  firebase: EnvConfig.FIREBASE,
};
