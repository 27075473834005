import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TranslateService {
  onLanguageChange: BehaviorSubject<{
    code: string;
    language: any;
  }>;
  STORAGE_KEY = 'language';
  version: string = environment.langVersion;
  defaultLangCode = environment.defaultLanguage;
  activeLanguage: any;
  code: string;

  constructor(private _http: HttpClient) {
    this.onLanguageChange = new BehaviorSubject({
      code: this.defaultLangCode,
      language: undefined,
    });
  }

  async set({ code }: { code: string }) {
    if (this.code === code) return;
    this.code = code;
    localStorage.setItem(this.STORAGE_KEY, this.code);
    await this.getActiveLanguage(this.code);
  }

  setDefaultLanguage({ code }: { code: string }) {
    this.defaultLangCode = code;
  }

  getActiveLanguage(code) {
    const params = new HttpParams({
      fromObject: {
        version: this.version,
      },
    });

    return new Promise<any>((resolve) => {
      this._http
        .get(this.langPath(code), {
          params,
        })
        .subscribe({
          next: (response) => {
            this.activeLanguage = response || {};
            this.onLanguageChange.next({
              code: code,
              language: response,
            });
            resolve(this.activeLanguage);
          },
          error: () => {
            this.activeLanguage = undefined;
            resolve(this.activeLanguage);
          },
        });
    });
  }

  getTranslation() {
    return this.activeLanguage;
  }

  getSavedCode() {
    return localStorage.getItem(this.STORAGE_KEY) || undefined;
  }

  langPath = (lang) => `assets/i18n/${lang || this.defaultLangCode}.json`;

  private _getNestedValue<ObjectType>(object: ObjectType, path: string) {
    if (!object) return;

    const keys = path.split('.');
    let result = object;
    for (const key of keys) {
      if (!result[key]) continue;
      result = result[key];
    }

    if (typeof result === 'object') {
      return path;
    }

    return result;
  }

  private _replaceParams(str: string, params?: { [key: string]: string }) {
    let newStr = str;

    Object.keys(params).forEach((key) => {
      newStr = newStr.replace(`{{${key}}}`, params[key]);
    });

    return newStr;
  }

  translate(value: string, params?: { [key: string]: string }) {
    const translation = this.getTranslation();
    if (!translation) return '';

    let keyValue = this._getNestedValue(translation, value) || '';

    if (params) {
      keyValue = this._replaceParams(keyValue, params);
    }

    return keyValue;
  }
}
