import { ApplicationRef, ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { I18N_LANGUAGES } from 'app/shared/constants/i18n-languages';
import { TranslateService } from 'app/shared/services/translate/translate.service';

@Component({
  selector: 'ooblee-toolbar-languages',
  templateUrl: './toolbar-languages.component.html',
  styleUrls: ['./toolbar-languages.component.scss'],
})
export class ToolbarLanguagesComponent implements OnInit {
  languages = I18N_LANGUAGES;
  control: FormControl;
  constructor(private _translateService: TranslateService, public zone: NgZone) {
    this.control = new FormControl(this._translateService.code);
  }

  ngOnInit(): void {}

  onLanguageChange(event: string) {
    if (!event) {
      this.control.setValue(this._translateService.code);
    } else {
      this._translateService.set({ code: event });
    }
  }
}
