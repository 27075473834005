import { Directive, ElementRef, HostBinding, OnInit } from '@angular/core';

@Directive({
  selector: '[kissElementOverflow]',
  inputs: ['kissElementOverflow'],
})
export class KissElementOverflowDirective implements OnInit {
  @HostBinding('class.kiss-element-overflow') isOverflown = false;
  constructor(private _elRef: ElementRef) {
    this.isOverflown = _elRef.nativeElement.scrollHeight > _elRef.nativeElement.clientHeight;
  }

  ngOnInit(): void {
    this.isOverflown =
      this._elRef.nativeElement.scrollHeight > this._elRef.nativeElement.clientHeight;
  }
}
