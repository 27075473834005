import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { KissLoadingBarService } from '@kiss/components/common';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoginCodeResolver
  implements Resolve<{ email: string | null; shopName: string | null; shopId: string | null }>
{
  constructor(private router: Router, private _kissLoadingBarService: KissLoadingBarService) {}

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<{ email: string | null; shopName: string | null; shopId: string | null }> {
    const email = route.queryParamMap.get('email');
    const shopName = route.queryParamMap.get('shopName');
    const shopId = route.queryParamMap.get('shopId');

    if (email && shopName && shopId) {
      return of({ email, shopName, shopId });
    } else {
      this.router.navigate(['/']);
      return;
    }
  }
}
