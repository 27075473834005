import { NgModule } from '@angular/core';

import { LogInModule } from './log-in/log-in.module';
import { ForgotPasswordModule } from './forgot-password/forgot-password.module';
import { LoginCodeModule } from './login-code/login-code.module';

@NgModule({
  declarations: [],
  imports: [LogInModule, ForgotPasswordModule, LoginCodeModule],
  exports: [],
})
export class AuthModule {}
