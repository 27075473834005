<Ooblee-header></Ooblee-header>
<div class="container" *ngIf="step === 'first'">
  <div class="login-container">
    <img src="assets/Auth/mobilePhone.svg" />

    <form (submit)="submit()" (keyup.enter)="submit()" [formGroup]="form" class="form">
      <p class="title">{{ 'passwordForgot.passwordForgotten' | translate }}</p>
      <p class="subtitle">{{ 'passwordForgot.reset' | translate }}</p>

      <input
        class="input-username"
        formControlName="userName"
        placeholder="{{ 'placeholders.email' | translate }}"
      />

      <p class="reset" *ngIf="form.get('userName').hasError('incorrect')">
        {{ 'passwordForgot.emailsNotMatch' | translate }}
      </p>
      <div
        (click)="submit()"
        [ngClass]="{
          valid: submitted || form.valid
        }"
        class="submit-button"
      >
        {{ 'passwordForgot.sendCode' | translate }}
      </div>
    </form>
  </div>
</div>
<div class="container" *ngIf="step === 'second'">
  <div class="login-container">
    <img src="assets/Auth/mobilePhone.svg" />

    <form
      (submit)="submitCode()"
      (keyup.enter)="submitCode()"
      [formGroup]="secondForm"
      class="form"
    >
      <p class="title">{{ 'passwordForgot.sendCode' | translate }}</p>
      <p class="subtitle">
        {{ 'passwordForgot.inboxCheck' | translate }}
      </p>

      <input
        class="input-username"
        formControlName="code"
        placeholder="{{ 'placeholders.codeResentSuccess' | translate }}"
      />

      <div
        (click)="submitCode()"
        [ngClass]="{
          valid: secondForm.valid
        }"
        class="submit-button"
      >
        {{ 'passwordForgot.sendCode' | translate }}
      </div>
      <div (click)="resendCode()" class="resend-button">
        {{ 'passwordForgot.newCodeRequest' | translate }}
      </div>
    </form>
  </div>
</div>

<div class="container" *ngIf="step === 'third'">
  <div class="login-container">
    <img src="assets/Auth/mobilePhone.svg" />

    <form
      (submit)="submitLastForm()"
      (keyup.enter)="submitLastForm()"
      [formGroup]="lastForm"
      class="form"
    >
      <p class="title">{{ 'passwordForgot.enterNewPassword' | translate }}</p>

      <div class="row_password">
        <input
          #elPass
          autocomplete="off"
          class="input-password"
          formControlName="password"
          placeholder="{{ 'passwordForgot.enterNewPassword' | translate }}"
          type="password"
        />
        <img
          (click)="onTogglePass(elPass)"
          *ngIf="lastForm.get('password').value != '' && elPass.type === 'password'"
          class="absolute_password"
          src="assets/Auth/password-hidden.svg"
        />
        <img
          (click)="onTogglePass(elPass)"
          *ngIf="lastForm.get('password').value != '' && elPass.type === 'text'"
          class="absolute_password"
          src="assets/Auth/openEye.svg"
        />
      </div>
      <div class="row_password">
        <input
          #elPass2
          autocomplete="off"
          class="input-password"
          formControlName="repeatedPassword"
          placeholder="{{ 'placeholders.newPasswordVerification' | translate }}"
          type="password"
        />
        <img
          (click)="onTogglePass(elPass2)"
          *ngIf="lastForm.get('repeatedPassword').value != '' && elPass2.type === 'password'"
          class="absolute_password"
          src="assets/Auth/password-hidden.svg"
        />
        <img
          (click)="onTogglePass(elPass2)"
          *ngIf="lastForm.get('repeatedPassword').value != '' && elPass2.type === 'text'"
          class="absolute_password"
          src="assets/Auth/openEye.svg"
        />
      </div>

      <p class="reset" *ngIf="lastForm.get('repeatedPassword').hasError('confirmedValidator')">
        {{ 'passwordForgot.passwordsMatchAlert' | translate }}
      </p>
      <div
        (click)="submitLastForm()"
        [ngClass]="{
          valid: lastForm.valid
        }"
        class="submit-button"
      >
        {{ 'passwordForgot.goToLogin' | translate }}
      </div>
    </form>
  </div>
</div>

<Ooblee-footer></Ooblee-footer>
