<ng-template #menu>
  <div
    #content
    class="kiss-menu-content"
    @zoomIn
    kissSetPosition
    [kissSetPositionParent]="menuTrigger"
    [xPosition]="xPosition"
    [yPosition]="yPosition"
    [handleOverflow]="handleOverflow"
    [ngClass]="[contentClass || '']"
  >
    <ng-content></ng-content>
  </div>
</ng-template>
