<button class="kiss-search__icon-btn" (focus)="input.focus()">
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19 19L14.65 14.65M17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</button>
<input
  #input
  ngDefaultControl
  autocomplete="on"
  [placeholder]="placeholder"
  [(ngModel)]="value"
  (ngModelChange)="updateChanges()"
  [disabled]="disabled"
  [readonly]="readonly"
  (change)="valueChange($event)"
  (input)="valueInput($event)"
/>
<button
  class="kiss-search__icon-btn kiss-search__close-btn"
  *ngIf="value"
  (click)="onClose($event)"
>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 1L1 13M1 1L13 13"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</button>
