import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { KissNavigationComponent } from './kiss-navigation.component';
import { NavigationSidebarComponent } from './kiss-navigation-group/kiss-navigation-group.component';
import { NavigationListItemComponent } from './kiss-navigation-item/kiss-navigation-item.component';
import { KissNavigationService } from './services/kiss-navigation.service';
import { KissNavigationListComponent } from './kiss-navigation-list/kiss-navigation-list.component';
import { SafeHtmlPipeModule } from '@kiss/pipes/safe-html';
import { TranslateModule } from 'app/shared/services/translate/translate.module';

@NgModule({
  declarations: [
    KissNavigationComponent,
    NavigationSidebarComponent,
    NavigationListItemComponent,
    KissNavigationListComponent,
  ],
  imports: [RouterModule, CommonModule, SafeHtmlPipeModule, TranslateModule],
  exports: [KissNavigationComponent],
  providers: [KissNavigationService],
})
export class KissNavigationModule {}

// NEEDS BETTER NAVIGATION
