import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { AuthenticationService } from '../auth/authentication.service';
import { Router } from '@angular/router';
import { LOGOUT_ROUTE } from '../constants/logout-route';
import { CacheService } from './cache/cache.service';

@Injectable({
  providedIn: 'root',
})
export class DataValidityService {
  private readonly storageName = 'app_version';
  version = environment.appVersion;

  constructor(
    private _authService: AuthenticationService,
    private _router: Router,
    private _cache: CacheService
  ) {}

  checkVersion() {
    const version = localStorage.getItem(this.storageName);

    if (version !== this.version) {
      this._authService.logoutLocally();
      localStorage.clear();

      //clear
      this._router.navigate([LOGOUT_ROUTE]);
      this._cache.clear();

      // save
      localStorage.setItem(this.storageName, this.version);
    }
  }
}
