<!-- TEMPLATE -->
<ng-template
  *ngIf="!overlayRef?.info?.useComponentOutlet"
  [ngTemplateOutlet]="template"
  [ngTemplateOutletContext]="{ $implicit: overlayRef }"
></ng-template>
<!-- !TEMPLATE -->

<!-- COMPONENT -->
<ng-template
  *ngIf="overlayRef?.info?.useComponentOutlet"
  [ngComponentOutlet]="template"
></ng-template>
<!-- !COMPONENT -->
