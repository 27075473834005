import { FormGroup } from '@angular/forms';

export const ConfirmedValidator = (controlName: string, matchingControlName: string) => {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (!matchingControl.value) {
      return;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ confirmedValidator: true });
    } else {
      matchingControl.setErrors({ confirmedValidator: null });

      matchingControl.setErrors(null);
    }
  };
};
