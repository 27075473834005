import { Directive, Inject, Input, IterableDiffers, Optional, TemplateRef } from "@angular/core";
import { BaseKissRowDirective } from "../base/base-kiss-row.directive";
import { KISS_TABLE } from "../helpers/kiss-table-injector";

@Directive({
  selector: "[kissHeaderRowDef]"
})
export class KissHeaderRowDefDirective extends BaseKissRowDirective {
  protected override _columns = [];
  @Input("kissHeaderRowDef") override set columns(value: string[]) {
    this._columns = value;
  }

  override get columns(): string[] {
    return this._columns;
  }

  constructor(
    public override template: TemplateRef<any>,
    protected override _differs: IterableDiffers,
    @Inject(KISS_TABLE) @Optional() public _table?: any
  ) {
    super(template, _differs);
  }
}
