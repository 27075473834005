<div class="spinner" [ngStyle]="{ width: size, height: size, color: color }">
  <div
    [ngStyle]="{
      border: thickness + ' solid currentColor',
      borderColor: 'currentColor transparent transparent transparent'
    }"
  ></div>
  <div
    [ngStyle]="{
      border: thickness + ' solid currentColor',
      borderColor: 'currentColor transparent transparent transparent'
    }"
  ></div>
  <div
    [ngStyle]="{
      border: thickness + ' solid currentColor',
      borderColor: 'currentColor transparent transparent transparent'
    }"
  ></div>
  <div
    [ngStyle]="{
      border: thickness + ' solid currentColor',
      borderColor: 'currentColor transparent transparent transparent'
    }"
  ></div>
</div>
