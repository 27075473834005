<div class="kiss-datepicker__day__weekdays">
  <div class="kiss-datepicker__day__weekdays__item" *ngFor="let day of daySettings.weekdays">
    {{ day.label }}
  </div>
</div>
<div class="kiss-datepicker__day__row" *ngFor="let row of calendar.dates">
  <button
    class="kiss-datepicker__btn kiss-datepicker__day__item"
    *ngFor="let cell of row"
    [ngClass]="{
      'kiss-datepicker__day__item--current': cell.isCurrentDay,
      'kiss-datepicker__day__item--prev': !cell.isCurrentMonth,
      'kiss-datepicker__day__item--between-range': cell?.isBetweenRange,
      'kiss-datepicker__day__item--between-range-hover': cell?.isBetweenRangeHover,
      'kiss-datepicker__day__item--active': cell.isSelected,
      'kiss-datepicker__day__item--disabled': cell?.isDisabledDate,
      'kiss-datepicker__day__item--start-range-date': cell?.isStartRangeDate,
      'kiss-datepicker__day__item--end-range-date': cell?.isEndRangeDate
    }"
    [disabled]="cell?.isDisabledDate"
    (click)="onDateClick($event, cell)"
    (mouseover)="onDateMouseover($event, cell)"
  >
    {{ cell?.value }}
  </button>
</div>
