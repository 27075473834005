import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Host,
  IterableDiffers,
  OnDestroy,
  Optional,
  ViewChild
} from "@angular/core";
import { KissHeaderCellOutletDirective } from "../kiss-header-cell/kiss-header-cell-outlet.directive";

@Component({
  selector: "kiss-header-row",
  template: `<ng-container kissHeaderCellOutlet></ng-container>`,
  host: {
    class: "kiss-header-row",
    role: "header-row"
  },
  changeDetection: ChangeDetectionStrategy.Default
})
export class KissHeaderRowComponent implements OnDestroy {
  @ViewChild(KissHeaderCellOutletDirective, { static: true }) headerCellOutlet: KissHeaderCellOutletDirective;

  constructor() {}

  ngOnDestroy(): void {
    this.headerCellOutlet._viewContainer.clear();
  }
}
