import { Injectable, OnDestroy } from '@angular/core';
import { KissDialogService } from '@kiss/components/common';
import { KissDialogRef } from '@kiss/components/common/kiss-dialog/helpers/kiss-dialog-ref';
import { SuportPromptComponent } from './supprot/suport-prompt.component';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";

const API_SUPORT = `${environment.baseUrl}/mobileapi/JsonContactSupport`;
@Injectable()
export class SuportService implements OnDestroy {
  confirmationDialogRef: KissDialogRef;

  constructor(private _http: HttpClient, private _kissDialogService: KissDialogService) {}

  open(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.confirmationDialogRef = this._kissDialogService.open(SuportPromptComponent, {
        data: {
          onAction: (value: boolean) => {
            resolve(value);
          },
        },
        dialogClass: 'suport-dialog',
        exitOnEsc: true,
        exitOnClickOutside: true,
      });
    });
  }

  sendSupportMessage(textMessage: string, subject: string) {
    const body = {
      textMessage: textMessage,
      subject: subject
    };
    return this._http.post(API_SUPORT, body);
  }

  ngOnDestroy(): void {
    if (this.confirmationDialogRef) this.confirmationDialogRef.destroy();
    this.confirmationDialogRef = null;
  }
}
