import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { KissTableComponent } from './kiss-table.component';
import { KissCellComponent } from './kiss-cell/kiss-cell.component';
import { KissHeaderCellComponent } from './kiss-header-cell/kiss-header-cell.component';
import { KissTableHeaderComponent } from './kiss-table-header/kiss-table-header.component';
import { KissRowComponent } from './kiss-row/kiss-row.component';
import { KissTableBodyComponent } from './kiss-table-body/kiss-table-body.component';
import { KissHeaderCellDefDirective } from './kiss-header-cell/kiss-header-cell-def.directive';
import { KissCellDefDirective } from './kiss-cell/kiss-cell-def.directive';
import { KissColumnDefDirective } from './kiss-column/kiss-column.directive';
import { KissRowDefDirective } from './kiss-row/kiss-row-def.directive';
import { KissHeaderRowDefDirective } from './kiss-header-row/kiss-header-row-def.directive';
import { KissHeaderRowComponent } from './kiss-header-row/kiss-header-row.component';
import { KissCellOutletDirective } from './kiss-cell/kiss-cell-outlet.directive';
import { KissRowOutletDirective } from './kiss-row/kiss-row-outlet.directive';
import { KissHeaderRowOutletDirective } from './kiss-header-row/kiss-header-row-outlet.directive';
import { KissHeaderCellOutletDirective } from './kiss-header-cell/kiss-header-cell-outlet.directive';

@NgModule({
  declarations: [
    KissTableComponent,
    KissCellComponent,
    KissRowComponent,
    KissHeaderCellComponent,
    KissTableHeaderComponent,
    KissTableBodyComponent,
    KissHeaderCellDefDirective,
    KissCellDefDirective,
    KissColumnDefDirective,
    KissRowDefDirective,
    KissHeaderRowComponent,
    KissHeaderRowDefDirective,
    KissCellOutletDirective,
    KissRowOutletDirective,
    KissHeaderRowOutletDirective,
    KissHeaderCellOutletDirective,
  ],
  imports: [CommonModule],
  exports: [
    KissTableComponent,
    KissCellComponent,
    KissRowComponent,
    KissHeaderCellComponent,
    KissHeaderCellDefDirective,
    KissCellDefDirective,
    KissColumnDefDirective,
    KissRowDefDirective,
    KissHeaderRowComponent,
    KissHeaderRowDefDirective,
  ],
})
export class KissTableModule {}
