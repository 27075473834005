<div class="row" [style.display]="state.value === 'inactive' ? 'none' : ''">
  <div class="col-9">
    <div *ngIf="title"
         [class]="options.titleClass"
         [attr.aria-label]="title"
         class="custom-title"
    >
      {{ title }}
    </div>
    <div
      *ngIf="message && options.enableHtml"
      role="alert"
      [class]="options.messageClass"
      [innerHTML]="message"
    ></div>
    <div
      *ngIf="message && !options.enableHtml"
      role="alert"
      [class]="options.messageClass"
      [attr.aria-label]="message"
      class="custom-message"
    >
      {{ message }}
    </div>
  </div>
  <div class="col-3 text-right">
    <button class="ooblee-btn close-btn" (click)="remove()">
      <kiss-svg-icon icon="close"></kiss-svg-icon>
    </button>
  </div>
</div>
<div *ngIf="options.progressBar">
  <div class="toast-progress" [style.width]="width + '%'"></div>
</div>
