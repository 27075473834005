import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { parseJsonString } from 'app/shared/helpers/parse-json-string';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserService {
  private readonly storageName = 'user_data';
  private userData = new BehaviorSubject(undefined);

  constructor(private _af: AngularFireAuth) {}

  getUserData() {
    const userData = localStorage.getItem(this.storageName);

    return parseJsonString(userData);
  }

  userDataChange() {
    return this.userData.asObservable();
  }

  updateUserData(user: any) {
    localStorage.setItem(this.storageName, JSON.stringify(user));
    this.userData.next(user);
  }

  setUserFromToken(res: string) {
    this.updateUserData(res);
  }

  clearUserData() {
    localStorage.removeItem(this.storageName);
    this.userData.next(undefined);
  }
}
