import { ChangeDetectionStrategy, Component, ElementRef } from "@angular/core";

@Component({
  selector: "kiss-table-header",
  template: "<ng-content></ng-content>",
  changeDetection: ChangeDetectionStrategy.Default,
  host: {
    class: "kiss-table-header",
    role: "table-header"
  }
})
export class KissTableHeaderComponent {
  constructor(public elementRef: ElementRef) {}
}
