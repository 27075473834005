<!-- LAYOUT VIEW EMPTY -->
<layout-empty *ngIf="config.layoutType === 'empty'"></layout-empty>
<!-- !LAYOUT VIEW EMPTY -->

<!-- LAYOUT VIEW VERTICAL CLASSIC -->
<layout-vertical-classic
  *ngIf="config.layoutType === 'vertical-classic'"
  [config]="config?.verticalClassicConfig"
></layout-vertical-classic>
<!-- !LAYOUT VIEW VERTICAL CLASSIC  -->
