import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UnAuthGuard } from '../../shared/guards/unauth.guard';
import { RouterModule } from '@angular/router';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { ForgotPasswordComponent } from './forgot-password.component';
import { HeaderModule } from '../header/header.module';
import { FooterModule } from '../footer/footer.module';
import { TranslateModule } from 'app/shared/services/translate/translate.module';

const routes = [
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [UnAuthGuard],
    data: {
      layoutConfig: {
        layoutType: 'vertical-classic',
        verticalClassicConfig: {
          navigationSidebar: {
            enabled: false,
          },
          toolbar: {
            enabled: false,
          },
        },
      },
    },
  },
];

@NgModule({
  declarations: [ForgotPasswordComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    HeaderModule,
    FooterModule,
    TranslateModule,
  ],
  providers: [UnAuthGuard],
  exports: [],
})
export class ForgotPasswordModule {}
