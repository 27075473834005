import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EmbeddedViewRef,
  Input,
  IterableChangeRecord,
  IterableDiffer,
  IterableDiffers,
  NgZone,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { of, Subject, Subscription, takeUntil } from 'rxjs';

import { KissColumnDefDirective } from './kiss-column/kiss-column.directive';
import { KissHeaderRowDefDirective } from './kiss-header-row/kiss-header-row-def.directive';
import { KissRowDefDirective } from './kiss-row/kiss-row-def.directive';
import { KissTableSettings } from './helpers/kiss-table-settings';
import { KissTableDefaults } from './helpers/kiss-table-defaults';
import { KissTableResizeHandler } from './helpers/kiss-table-resize-handler';
import { KissRowOutletDirective } from './kiss-row/kiss-row-outlet.directive';
import { KissHeaderRowOutletDirective } from './kiss-header-row/kiss-header-row-outlet.directive';
import { BaseKissCellOutletDirective } from './base/base-kiss-cell-outlet.directive';
import { KISS_TABLE } from './helpers/kiss-table-injector';
import { RenderRow } from './helpers/kiss-table-rendered-rows';

@Component({
  selector: 'kiss-table',
  templateUrl: './kiss-table.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  host: {
    class: 'kiss-table',
    role: 'table',
  },
  providers: [{ provide: KISS_TABLE, useExisting: KissTableComponent }],
})
export class KissTableComponent implements OnInit, AfterContentChecked, OnDestroy {
  // INPUTS
  /** Latest data provided by the data source. */
  private _dataSource: any[] = [];
  private _data: any[] = [];
  private _dataDiffer: IterableDiffer<any>;

  @Input() set dataSource(value: any[]) {
    if (this._dataSource === value) return;

    this._updateDataSource(value);
  }

  get dataSource(): any[] {
    return this._dataSource;
  }
  /**
   * All columns that will be displayed
   */
  @Input() displayColumns: string[] = [];

  private _settings: KissTableSettings;
  @Input() set settings(value: any) {
    if (value instanceof KissTableSettings == false) {
      this._settings = new KissTableSettings(value);
    }

    //type
    if (this._settings.type) {
      this._toggleTableType(this._settings.type);
    }

    //sticky
    if (this._settings.header.sticky) {
      this._toggleStickyHeaderClass(this._settings.header.sticky);
    }

    this._resizeHandler.settings = this._settings;
  }

  get settings() {
    return this._settings;
  }

  /**
   * External class that handles kiss table resizing
   */
  private _resizeHandler: KissTableResizeHandler;

  /**
   * CONTENT CHILDREN
   */
  @ContentChildren(KissRowDefDirective, { descendants: true })
  private _contentRowDefs: QueryList<KissRowDefDirective>;
  @ContentChildren(KissHeaderRowDefDirective, { descendants: true })
  private _contentHeaderRowDefs: QueryList<KissHeaderRowDefDirective>;

  /**
   * Get all column directives
   */
  @ContentChildren(KissColumnDefDirective, { descendants: true })
  private _contentColumnDefs: QueryList<KissColumnDefDirective>;

  /**
   * Whether the header row definition has been changed. Triggers an update to the header row after
   * content is checked. Initialized as true so that the table renders the initial set of rows.
   */
  private _headerRowDefChanged: boolean = true;

  /**
   * Outlets
   */
  @ViewChild(KissRowOutletDirective, { static: true }) private _rowOutlet: KissRowOutletDirective;
  @ViewChild(KissHeaderRowOutletDirective, { static: true })
  private _headerRowOutlet: KissHeaderRowOutletDirective;

  //FILTERED ROWS AND COLUMNS
  private _columnDefsByName = new Map<string, KissColumnDefDirective>();
  private _rowDefs: KissRowDefDirective[];
  private _headerRowDefs: KissHeaderRowDefDirective[];

  /** Stores the row definition that does not have a when predicate. */
  private _defaultRowDef: KissRowDefDirective | null;

  /** List of the rendered rows as identified by their `RenderRow` object. */
  private _renderedRows: RenderRow<any>[];

  /**
   * Cache of the latest rendered `RenderRow` objects as a map for easy retrieval when constructing
   * a new list of `RenderRow` objects for rendering rows. Since the new list is constructed with
   * the cached `RenderRow` objects when possible, the row identity is preserved when the data
   * and row template matches, which allows the `IterableDiffer` to check rows by reference
   * and understand which rows are added/moved/removed.
   *
   * Implemented as a map of maps where the first key is the `data: T` object and the second is the
   * `KissRowDefDirective` object. With the two keys, the cache points to a `RenderRow<T>` object that
   * contains an array of created pairs. The array is necessary to handle cases where the data
   * array contains multiple duplicate data objects and each instantiated `RenderRow` must be
   * stored.
   */
  private _cachedRenderedRowsMap = new Map<any, WeakMap<KissRowDefDirective, RenderRow<any>[]>>();

  //resize
  private _destroy: Subject<void>;
  private _renderChangeSubscription: Subscription;

  constructor(
    private _cdr: ChangeDetectorRef,
    private iterableDiffers: IterableDiffers,
    private _renderer: Renderer2,
    private _elRef: ElementRef,
    private _ngZone: NgZone
  ) {
    this._resizeHandler = new KissTableResizeHandler(_renderer, _elRef, _ngZone);
    this._destroy = new Subject<void>();
  }

  ngOnInit(): void {
    //compare row differences
    if (this.settings?.trackBy) {
      console.warn('trackBy might not work as intended, test it out please!');
    }

    this._dataDiffer = this.iterableDiffers.find([]).create((_i: number, data: any) => {
      return this.settings?.trackBy ? this.settings.trackBy(data.dataIndex, data.data) : data;
    });

    if (!this.settings) {
      this._toggleTableType();
      this._toggleStickyHeaderClass();
    }
  }

  ngAfterContentChecked(): void {
    // filter columns and rows
    this._saveRowDefs();
    this._saveColumnDefs();

    // Make sure that the user has at least added header, footer, or data row def.
    if (!this._headerRowDefs?.length && !this._rowDefs?.length) {
      throw Error('kiss-header or kiss-row are missing');
    }

    const columnsChanged = this._renderUpdatedColumns();

    //first time render

    if (this._headerRowDefChanged) {
      this._forceRenderHeaderRows();
      this._headerRowDefChanged = false;
    }

    if (this.dataSource && this._rowDefs?.length && !this._renderChangeSubscription) {
      this._observeRenderChanges();
    }
  }

  /**
   * AfterViewInit
   */
  ngAfterViewInit(): void {
    this._resizeHandler.listenForResize();

    // /**
    //  * Detect if the table row has changed and update the size
    //  * More preferable than using detectChanges and isFirstTime in @Input
    //  */
    // this.rowRef.changes.pipe(takeUntil(this._destroy)).subscribe(() => {
    //   this._updateCellSizes();
    // });
  }

  /**
   * Check if columns have changed, if they have re-render rows
   * @returns
   */
  private _renderUpdatedColumns() {
    const columnsDiffReducer = (acc: boolean, def: any): boolean => acc || !!def.getColumnsDiff();

    const dataColumnsChanged = this._rowDefs.reduce(columnsDiffReducer, false);

    if (dataColumnsChanged) {
      this._forceRenderRows();
    }

    const headerColumnsChanged = this._headerRowDefs.reduce(columnsDiffReducer, false);

    if (headerColumnsChanged) {
      this._forceRenderHeaderRows();
    }

    return dataColumnsChanged || headerColumnsChanged;
  }

  /**
   * Listen for data changes and render rows when it executes
   * @returns
   */
  private _observeRenderChanges() {
    // If no data source has been set, there is nothing to observe for changes.
    if (!this.dataSource) {
      return;
    }

    const dataStream = of(this.dataSource);

    this._renderChangeSubscription = dataStream!
      .pipe(takeUntil(this._destroy))
      .subscribe((data) => {
        this._data = data || [];
        //CHANGE THIS TO WHEN IMPLEMENTING TRACK BY  this._renderRows();
        this.renderRows();
      });
  }

  /**
   * Update datasource and rerender rows, if it does not exist clear the rowOutlet
   * @param dataSource
   */
  private _updateDataSource(dataSource: any) {
    // Stop listening for data from the previous data source.
    if (this._renderChangeSubscription) {
      this._renderChangeSubscription.unsubscribe();
      this._renderChangeSubscription = null;
    }

    if (!dataSource) {
      if (this._dataDiffer) {
        this._dataDiffer.diff([]);
      }

      this._rowOutlet.viewContainer.clear();
    }

    this._dataSource = dataSource;
  }

  /**
   * Toggles the table type between flex and table
   * @param value
   */
  private _toggleTableType(value?: string) {
    if (!value || value === 'flex') {
      this._renderer.removeClass(this._elRef.nativeElement, KissTableDefaults.styling.typeTable);
      this._renderer.addClass(this._elRef.nativeElement, KissTableDefaults.styling.typeFlex);
    }
    if (value === 'table') {
      this._renderer.removeClass(this._elRef.nativeElement, KissTableDefaults.styling.typeFlex);
      this._renderer.addClass(this._elRef.nativeElement, KissTableDefaults.styling.typeTable);
    }
  }

  /**
   * Enables/Disables the sticky header by adding sticky-header table
   * @param value
   */
  private _toggleStickyHeaderClass(value?: boolean) {
    if (value) {
      this._renderer.addClass(this._elRef.nativeElement, KissTableDefaults.styling.stickyHeader);
    } else {
      this._renderer.removeClass(this._elRef.nativeElement, KissTableDefaults.styling.stickyHeader);
    }
  }

  /**
   *  Update column defs and filter out nested tables
   */
  private _saveColumnDefs() {
    this._columnDefsByName.clear();

    const colDefs = this._getOwnDefs(this._contentColumnDefs);

    colDefs.forEach((item) => {
      if (this._columnDefsByName.has(item.name)) {
        throw new Error(`Duplicate column ${item.name}`);
      }

      this._columnDefsByName.set(item.name, item);
    });
  }

  private _saveRowDefs() {
    this._rowDefs = this._getOwnDefs(this._contentRowDefs);
    this._headerRowDefs = this._getOwnDefs(this._contentHeaderRowDefs);

    // After all row definitions are determined, find the row definition to be considered default.
    const defaultRowDefs = this._rowDefs.filter((def) => !def.when);
    this._defaultRowDef = defaultRowDefs[0];
  }

  /** Filters definitions that belong to this table from a QueryList. */
  private _getOwnDefs<I extends { _table?: any }>(items: QueryList<I>): I[] {
    return items.filter((item) => !item._table || item._table === this);
  }

  /**
   * Render rows inside row outlet. We listen for array changes on data.
   * For each change we check if the data is inserted, moved or removed and replicate it in the view
   * The most complex is insert where we need to create context, insert it into view and generate cells inside that view
   * createEmbeddedView displays each row as a outlet sibling.
   *
   */
  renderRows() {
    if (!this._rowDefs?.length) {
      return;
    }

    this._renderedRows = this._getRenderedRows();

    // FIX ROW RENDERING HERE
    const changes = this._dataDiffer.diff(this._renderedRows);

    //DATA HAS NOT CHANGED
    if (!changes) {
      return;
    }

    changes.forEachOperation(
      (
        record: IterableChangeRecord<any>,
        adjustedPreviousIndex: number | null,
        currentIndex: number | null
      ) => {
        if (record.previousIndex === null) {
          // DATA INSERTED
          const ctx = {
            $implicit: record.item.data,
            index: currentIndex,
            even: currentIndex % 2 === 0,
            odd: currentIndex % 2 !== 0,
            first: currentIndex === 0,
            last: currentIndex === this._renderedRows.length - 1,
          };

          const rowDef = record.item.rowDef;

          const viewRef = this._rowOutlet.viewContainer.createEmbeddedView(
            rowDef.template,
            ctx,
            currentIndex
          );
          const templates = this._getTemplatesByDefColumns({
            dir: rowDef,
            columns: this._columnDefsByName,
          });

          this._renderCells(templates, viewRef.context);
        } else if (currentIndex == null) {
          // DATA REMOVED
          this._rowOutlet.viewContainer.remove(adjustedPreviousIndex!);
        } else {
          // DATA MOVED
          const viewRef = this._rowOutlet.viewContainer.get(
            adjustedPreviousIndex!
          ) as EmbeddedViewRef<any>;

          //update context on data move
          viewRef.context.index = currentIndex;
          viewRef.context.even = currentIndex % 2 === 0;
          viewRef.context.odd = currentIndex % 2 !== 0;
          viewRef.context.first = currentIndex === 0;
          viewRef.context.last = currentIndex === this._renderedRows.length - 1;

          this._rowOutlet.viewContainer.move(viewRef, currentIndex);
        }
      }
    );
  }

  /**
   * Get the list of  KissTableRenderedRows objects to render according to the current list of data and defined
   * row definitions. If the previous list already contained a particular pair, it should be reused
   * so that the differ equates their references.
   */
  private _getRenderedRows(): RenderRow<any>[] {
    const renderRows: RenderRow<any>[] = [];

    // Store the cache and create a new one. Any re-used RenderRow objects will be moved into the
    // new cache while unused ones can be picked up by garbage collection.
    const prevCachedRenderedRows = this._cachedRenderedRowsMap;
    this._cachedRenderedRowsMap = new Map();

    // For each data object, get the list of rows that should be rendered, represented by the
    // respective `RenderRow` object which is the pair of `data` and `KissRowDefDirective`.
    for (let i = 0; i < this._data.length; i++) {
      let data = this._data[i];
      const renderRowsForData = this._getRenderRowsForData(
        data,
        i,
        prevCachedRenderedRows.get(data)
      );

      if (!this._cachedRenderedRowsMap.has(data)) {
        this._cachedRenderedRowsMap.set(data, new WeakMap());
      }

      for (let j = 0; j < renderRowsForData.length; j++) {
        let renderRow = renderRowsForData[j];

        const cache = this._cachedRenderedRowsMap.get(renderRow.data)!;
        if (cache.has(renderRow.rowDef)) {
          cache.get(renderRow.rowDef)!.push(renderRow);
        } else {
          cache.set(renderRow.rowDef, [renderRow]);
        }
        renderRows.push(renderRow);
      }
    }

    return renderRows;
  }

  /**
   * Gets a list of `RenderRow<T>` for the provided data object and any `KissRowDefDirective` objects that
   * should be rendered for this data. Reuses the cached RenderRow objects if they match the same
   * `(T, KissRowDefDirective)` pair.
   */
  private _getRenderRowsForData(
    data: any,
    dataIndex: number,
    cache?: WeakMap<KissRowDefDirective, RenderRow<any>[]>
  ): RenderRow<any>[] {
    const rowDefs = this._getRowDefs(data, dataIndex);

    return rowDefs.map((rowDef) => {
      const cachedRenderRows = cache && cache.has(rowDef) ? cache.get(rowDef)! : [];

      if (cachedRenderRows.length) {
        const dataRow = cachedRenderRows.shift()!;
        dataRow.dataIndex = dataIndex;
        return dataRow;
      } else {
        return { data, rowDef, dataIndex };
      }
    });
  }

  /**
   * Get the matching row definitions that should be used for this row data. If there is only
   * one row definition, it is returned. Otherwise, find the row definitions that has a when
   * predicate that returns true with the data. If none return true, return the default row
   * definition.
   */
  _getRowDefs(data: any, dataIndex: number): KissRowDefDirective[] {
    if (this._rowDefs.length == 1) {
      return [this._rowDefs[0]];
    }

    const rowDefs = this._rowDefs.filter((def) => !def.when || def.when(dataIndex, data));
    if (rowDefs?.length) {
      return rowDefs;
    }

    return [this._defaultRowDef];
  }

  /**
   * Render header columns
   * @returns
   */
  private _renderHeaderRow() {
    if (!this._headerRowDefs?.length) {
      return;
    }

    this._headerRowDefs.forEach((rowDef) => {
      this._headerRowOutlet.viewContainer.createEmbeddedView(rowDef.template);
      const templates = this._getTemplatesByDefColumns({
        dir: rowDef,
        columns: this._columnDefsByName,
      });

      this._renderCells(templates);
    });
  }

  /**
   * Render cells for each created row
   * @param templates
   * @param ctx
   */
  private _renderCells(templates: TemplateRef<any>[], ctx?: any) {
    templates.forEach((item, index) => {
      if (BaseKissCellOutletDirective.mostRecentCellOutlet) {
        BaseKissCellOutletDirective.mostRecentCellOutlet._viewContainer.createEmbeddedView(
          item,
          ctx,
          index
        );
      }
    });
  }

  /**
   * Get all templates for rows
   * @param options
   */
  private _getTemplatesByDefColumns({
    dir,
    columns,
  }: {
    dir: KissRowDefDirective | KissHeaderRowDefDirective;
    columns: Map<string, KissColumnDefDirective>;
  }) {
    if (!dir?.columns) return [];

    return Array.from(dir.columns, (id) => {
      const column = columns.get(id);

      if (!column) {
        throw new Error(`Column ${id} not found!`);
      }

      if (dir instanceof KissHeaderRowDefDirective) {
        return column.headerCell.template;
      } else {
        return column.cell.template;
      }
    });
  }

  /**
   * Forces a re-render of the data rows. Should be called in cases where there has been an input
   * change that affects the evaluation of which rows should be rendered
   */
  private _forceRenderRows() {
    this._dataDiffer.diff([]);
    this._rowOutlet.viewContainer.clear();
    this.renderRows();
  }

  private _forceRenderHeaderRows() {
    if (this._headerRowOutlet.viewContainer.length > 0) {
      this._headerRowOutlet.viewContainer.clear();
    }

    this._renderHeaderRow();
  }

  /**
   * Clear data sets, columns, maps
   */
  private _clearData() {
    [
      this._rowOutlet.viewContainer,
      this._headerRowOutlet.viewContainer,
      this._cachedRenderedRowsMap,
      this._columnDefsByName,
    ].forEach((item) => {
      item.clear();
    });

    this._headerRowDefs = [];
    this._defaultRowDef = null;
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this._clearData();

    this._resizeHandler.destroyListeners();
    this._resizeHandler = null;

    this.dataSource = null;
    this._data = null;

    this._destroy.next();
    this._destroy.complete();
  }
}
