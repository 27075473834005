import { Inject, Injectable } from '@angular/core';
import { KissSvgIconSettings } from './kiss-svg-icon-settings.type';

//@Injectable({ providedIn: 'root' })
@Injectable()
export class KissSvgIconService {
  private _settings: KissSvgIconSettings;
  constructor(@Inject('kissSvgIconSettings') private settings: any) {
    this._settings = this.settings ?? {};
  }

  getSettings(): KissSvgIconSettings {
    return this._settings;
  }
}
