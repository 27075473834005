<label class="kiss-checkbox__label">
  <input
    class="kiss-checkbox__input"
    tabindex="-1"
    type="checkbox"
    [ngModel]="checked"
    [disabled]="disabled"
    (ngModelChange)="onModelChange($event)"
  />
  <div class="kiss-checkbox__checkmark-container">
    <div class="kiss-checkbox__checkmark" *ngIf="checked">
      <svg *ngIf="!icon" width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1 5.7L3.85714 8.5L10 1.5"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <ng-template
        *ngIf="icon"
        [ngTemplateOutlet]="icon.templateRef"
        [ngTemplateOutletContext]="{ checked, disabled }"
      ></ng-template>
    </div>
  </div>
  <div class="kiss-checkbox__content">
    <ng-content></ng-content>
  </div>
</label>
