import { Injectable, OnDestroy } from '@angular/core';
import { asyncScheduler, BehaviorSubject, fromEvent, Observable, scheduled, Subject } from 'rxjs';
import { distinctUntilChanged, map, mergeAll, takeUntil } from 'rxjs/operators';
import {
  KISS_MEDIA_OBSERVER_CUSTOM_SCREEN_SIZES,
  KISS_MEDIA_OBSERVER_DEFAULT_SCREEN_SIZES,
  SCREEN_SIZE,
} from './defaults';
import { KISS_MEDIA_OBSERVER_SCREEN_SIZE } from './types/kiss-media-observer-size.types';

interface Size extends KISS_MEDIA_OBSERVER_SCREEN_SIZE {}

/**
 * Service that allows you to listen for window resize event and depending on the method checks which screen size is being used.
 *
 * Screen sizes are seperated into default ones and custom ones and can be found inside the defaults.ts file that contains:
 * @example
 * KISS_MEDIA_OBSERVER_DEFAULT_SCREEN_SIZES
 * KISS_MEDIA_OBSERVER_CUSTOM_SCREEN_SIZES
 *
 * @interface {KISS_MEDIA_OBSERVER_SCREEN_SIZE} - Interface containing screen size types
 *
 *
 */
@Injectable()
export class KissMediaObserverService implements OnDestroy {
  private _defaultSizes: Size[] = KISS_MEDIA_OBSERVER_DEFAULT_SCREEN_SIZES;
  private _customSizes: Size[] = KISS_MEDIA_OBSERVER_CUSTOM_SCREEN_SIZES;
  private _sizes: Size[] = [];

  /**
   * Observable that triggers when all screen sizes are updated
   */
  public onScreenSizeUpdate: BehaviorSubject<Size[]>;

  /**
   * Observable that triggers when custom screen sizes are updated
   */
  public onCustomScreenSizeUpdate: BehaviorSubject<Size[]>;

  //private
  private _unsubscribeAll: Subject<void>;
  private _triggerOnStart: BehaviorSubject<any>;
  constructor() {
    this._sizes = [...this._defaultSizes, ...this._customSizes];

    this._unsubscribeAll = new Subject();
    this._triggerOnStart = new BehaviorSubject(null);
    this.onScreenSizeUpdate = new BehaviorSubject(this._sizes);
    this.onCustomScreenSizeUpdate = new BehaviorSubject(this._customSizes);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this._triggerOnStart.unsubscribe();
    this.onScreenSizeUpdate.unsubscribe();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  private _onWindowResize(callback: (...args: any) => any): Observable<any> {
    return scheduled([fromEvent(window, 'resize'), this._triggerOnStart], asyncScheduler).pipe(
      mergeAll(),
      map(callback),
      distinctUntilChanged(),
      takeUntil(this._unsubscribeAll)
    );
  }

  private _compareWidth = (size: Size) =>
    window.screen.width >= size.minWidth && (window.screen.width <= size.maxWidth || size.maxWidth == null);

  private _compareHeight = (size: Size) =>
    window.screen.height >= size.minHeight && (window.screen.height <= size.maxHeight || size.maxHeight == null);

  private _matchesCurrentSize(size: Size) {
    //compare width
    if (this._compareWidth(size) && !size.minHeight && !size.maxHeight) {
      return size;
    }

    //compare width & height
    if (this._compareWidth(size) && this._compareHeight(size)) {
      return size;
    }
  }

  private _getSizeByValue(size: Size, value: any) {
    return size.id === value || size.name === value?.toString()?.toLowerCase();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Listens for changes on the window resize event and it triggers as soon as it's subscribed on
   *
   * @param {(string[] | string)} value - media queries passed as a string or strings[]
   *
   * @return {Observable<boolean>} Observable containing a boolean value of the matching media-query
   * @example
   * this._kissMediaObserver.observeMediaQueries('(max-width: 700px)')
   *    .subscribe(data => console.log(data));
   *
   * this._kissMediaObserver.observeMediaQueries(['(max-width: 700px)', '(min-width: 500px)'])
   *    .subscribe(data => console.log(data));
   */
  observeMediaQueries(values: string[] | string): Observable<boolean> {
    const arrayValues: string[] = Array.isArray(values) ? values : [values];

    return this._onWindowResize(() => {
      return arrayValues?.some((value) => window.matchMedia(value)?.matches);
    });
  }

  /**
   * Listens for changes on the window resize event and it triggers as soon as it's subscribed on
   *
   * @param {((SCREEN_SIZE | Size['name'])[] | SCREEN_SIZE | Size['name'])} value
   * - screen sizes that you would like to check. Can be a string or SCREEN_SIZE enum values or KISS_MEDIA_OBSERVER_SCREEN_SIZE
   * (Size extends KISS_MEDIA_OBSERVER_SCREEN_SIZE) name values
   *
   * @return {Observable<boolean>} Observable containing a boolean value of the matching screen size
   *
   * @example
   * this._kissMediaObserver.observeScreenSizes('sm')
   *    .subscribe(data => console.log(data));
   *
   * this._kissMediaObserver.observeScreenSizes(SCREEN_SIZE.LG)
   *    .subscribe(data => console.log(data));
   *
   * this._kissMediaObserver.observeScreenSizes([SCREEN_SIZE.MD, 'sm'])
   *    .subscribe(data => console.log(data));
   */
  observeScreenSizes(values: (SCREEN_SIZE | Size['name'])[] | SCREEN_SIZE | Size['name']): Observable<boolean> {
    const arrayValues: (SCREEN_SIZE | Size['name'])[] = Array.isArray(values) ? values : [values];

    return this._onWindowResize(() => {
      //FOR EVERY VALUE PASSED
      return arrayValues?.some((value) => {
        for (const size of this._sizes) {
          //if screen_size id or name matches
          if (this._getSizeByValue(size, value)) {
            const tmpSize = this._matchesCurrentSize(size);
            if (tmpSize) return true;
          }
        }
      });
    });
  }

  /**
   * Listens for changes on the window resize event and returns the currest default screen size (ignores the custom sizes)
   *
   * @return {Observable<KISS_MEDIA_OBSERVER_SCREEN_SIZE>} Observable containing a KISS_MEDIA_OBSERVER_SCREEN_SIZE value of the default screen size
   *
   * @example
   * this._kissMediaObserver.getCurrentScreenSize()
   *    .subscribe(data => console.log(data));
   */
  getCurrentScreenSize(): Observable<Size> {
    return this._onWindowResize(() => {
      for (const size of this._defaultSizes) {
        const tmpSize = this._matchesCurrentSize(size);
        if (tmpSize) return { ...tmpSize };
      }
    });
  }

  /**
   * Updates all screen sizes
   * @param {Size[]} sizes
   */
  updateScreenSizes(sizes: Size[]) {
    this._sizes = sizes;
    this.onScreenSizeUpdate.next(this._sizes);
  }

  /**
   * Updates custom screen sizes and triggers update on all screen sizes
   * @param {Size[]} customSizes
   */
  updateCustomScreenSizes(customSizes: Size[]) {
    this._customSizes = customSizes;
    this.onCustomScreenSizeUpdate.next(this._customSizes);
    this.updateScreenSizes([...this._defaultSizes, ...this._customSizes]);
  }

  /**
   * Returns a copy of custom screen sizes
   */
  getCustomScreenSizes() {
    return this._customSizes.slice();
  }

  /**
   * Returns a copy of all screen sizes
   */
  getScreenSizes() {
    return this._sizes.slice();
  }
}
