import {
  ChangeDetectorRef,
  Directive,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { KissOverlayRef } from './kiss-overlay-ref';
import { KissOverlayService } from './kiss-overlay.service';

/**
 * USAGE
 *
 * @example
 *
 * <ng-template kissOverlay  [kissOverlayContainerClass]="'test'" [kissOverlayOpen]="open" (onContainerClick)="onContainerClick()">
 * ...
 * </ng-template>
 */
@Directive({
  selector: '[kissOverlay]',
  inputs: ['kissOverlayOpen', 'kissOverlayContainerClass'],
  outputs: ['onContainerClick', 'onOverlayRemoved'],
})
export class KissOverlayDirective implements OnDestroy {
  private _overlayRef: KissOverlayRef;

  private _open = false;
  /**
   * Toggle when overlay is open
   * @param {boolean | string}
   */
  @Input() set kissOverlayOpen(value: any) {
    this._open = value || value === '';
    this._openToggle();
  }

  private _containerClass = '';
  /**
   * Add a backdrop class
   * @param string
   */
  @Input() set kissOverlayContainerClass(value: string) {
    this._containerClass = value || '';
  }

  private _fixedContainer = true;
  /**
   * Enables/Disabled container `position:fixed`
   */
  @Input() set kissOverlayFixedContainer(value: boolean) {
    this._fixedContainer = value;
  }

  /**
   * Fires when overlay container is clicked
   */
  @Output() onContainerClick = new EventEmitter();

  /**
   * Fires when overlay is removed
   */
  @Output() onOverlayRemoved = new EventEmitter();

  private _unsubAll: Subject<void>;
  constructor(
    private _templateRef: TemplateRef<any>,
    private _kissOverlayService: KissOverlayService,
    private _cdr: ChangeDetectorRef
  ) {
    this._unsubAll = new Subject();
  }

  // -----------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------

  ngOnDestroy(): void {
    this._removeOverlay();
  }

  // -----------------------------------------------
  // @ Private methods
  // -----------------------------------------------

  private _createOverlay() {
    this._overlayRef = this._kissOverlayService.createOverlay(this._templateRef, {
      containerClass: this._containerClass,
      fixedContainer: this._fixedContainer,
    });

    this._setupOverlayEventListeners();

    this._cdr.markForCheck();
  }

  private _removeOverlay() {
    this._unsubAll.next();
    this._unsubAll.complete();
    if (this._overlayRef) this._kissOverlayService.removeOverlay(this._overlayRef);
    this._overlayRef = undefined;
  }

  private _openToggle() {
    if (this._open) {
      this._createOverlay();
    } else {
      this._removeOverlay();
    }
  }

  private _setupOverlayEventListeners() {
    this._overlayRef.onContainerClick.pipe(takeUntil(this._unsubAll)).subscribe(() => {
      this.onContainerClick.next(this._overlayRef);
    });

    this._overlayRef.onOverlayRemoved.pipe(takeUntil(this._unsubAll)).subscribe(() => {
      this.onOverlayRemoved.next(this._overlayRef);
    });
  }
}
