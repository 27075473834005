<div
  class="kiss-loader__circle"
  *ngIf="type === 'circle'"
  [ngStyle]="{ width: size, height: size, fontSize: size }"
>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>
