<div class="add-category-dialog__header" *ngIf="step === 'first'">
  <button class="ooblee-btn add-category-dialog__header__close" (click)="onClose()">
    <kiss-svg-icon icon="close"></kiss-svg-icon>
  </button>
  <form (submit)="submit()" (keyup.enter)="submit()" [formGroup]="form" class="form">
    <p class="add-category-dialog__header__title">{{ 'placeholders.email' | translate }}</p>
    <p class="add-category-dialog__header__subtitle">{{ 'passwordForgot.reset' | translate }}</p>
    <div class="add-category-dialog__body">
      <input
        class="ooblee-input"
        formControlName="userName"
        placeholder="{{ 'placeholders.email' | translate }}"
      />

      <p class="reset" *ngIf="form.get('userName').hasError('incorrect')">
        {{ 'passwordForgot.emailsNotMatch' | translate }}
      </p>
    </div>
    <div class="add-category-dialog__footer">
      <div
        (click)="submit()"
        [ngClass]="{
          valid: submitted || form.valid
        }"
        class="ooblee-btn__flat ooblee-btn__flat--primary"
      >
        {{ 'passwordForgot.sendCode' | translate }}
      </div>
    </div>
  </form>
</div>
<div class="add-category-dialog__header" *ngIf="step === 'second'">
  <button class="ooblee-btn add-category-dialog__header__close" (click)="onClose()">
    <kiss-svg-icon icon="close"></kiss-svg-icon>
  </button>

  <form
    (submit)="submitCode()"
    (keyup.enter)="submitCode()"
    [formGroup]="secondForm"
    class="form"
  >
    <p class="add-category-dialog__header__title">{{ 'passwordForgot.sendCode' | translate }}</p>
    <p class="add-category-dialog__header__subtitle">
      {{ 'passwordForgot.inboxCheck' | translate }}
    </p>

    <input
      class="ooblee-input max-width"
      formControlName="code"
      placeholder="{{ 'placeholders.codeResentSuccess' | translate }}"
    />
    <div class="add-category-dialog__footer-second">
      <div (click)="resendCode()" class="ooblee-btn__stroked ooblee-btn__stroked--primary">
        {{ 'passwordForgot.newCodeRequest' | translate }}
      </div>
      <div
        (click)="submitCode()"
        [ngClass]="{
          valid: secondForm.valid
        }"
        class="ooblee-btn__flat ooblee-btn__flat--primary"
      >
        {{ 'passwordForgot.sendCode' | translate }}
      </div>
    </div>
  </form>

</div>

<div class="add-category-dialog__header" *ngIf="step === 'third'">
  <button class="ooblee-btn add-category-dialog__header__close" (click)="onClose()">
    <kiss-svg-icon icon="close"></kiss-svg-icon>
  </button>

  <form
    (submit)="submitLastForm()"
    (keyup.enter)="submitLastForm()"
    [formGroup]="lastForm"
    class="form"
  >
    <p class="add-category-dialog__header__title pb-1">{{ 'passwordForgot.enterNewPassword' | translate }}</p>

    <div class="row_password">
      <input
        #elPass
        autocomplete="off"
        class="ooblee-input max-width"
        formControlName="password"
        placeholder="{{ 'passwordForgot.enterNewPassword' | translate }}"
        type="password"
      />
      <img
        (click)="onTogglePass(elPass)"
        *ngIf="lastForm.get('password').value != '' && elPass.type === 'password'"
        class="absolute_password"
        src="assets/Auth/password-hidden.svg"
      />
      <img
        (click)="onTogglePass(elPass)"
        *ngIf="lastForm.get('password').value != '' && elPass.type === 'text'"
        class="absolute_password"
        src="assets/Auth/openEye.svg"
      />
    </div>
    <div class="row_password">
      <input
        #elPass2
        autocomplete="off"
        class="ooblee-input max-width"
        formControlName="repeatedPassword"
        placeholder="{{ 'placeholders.newPasswordVerification' | translate }}"
        type="password"
      />
      <img
        (click)="onTogglePass(elPass2)"
        *ngIf="lastForm.get('repeatedPassword').value != '' && elPass2.type === 'password'"
        class="absolute_password"
        src="assets/Auth/password-hidden.svg"
      />
      <img
        (click)="onTogglePass(elPass2)"
        *ngIf="lastForm.get('repeatedPassword').value != '' && elPass2.type === 'text'"
        class="absolute_password"
        src="assets/Auth/openEye.svg"
      />
    </div>

    <p class="reset" *ngIf="lastForm.get('repeatedPassword').hasError('confirmedValidator')">
      {{ 'passwordForgot.passwordsMatchAlert' | translate }}
    </p>
    <div
      (click)="submitLastForm()"
      [ngClass]="{
          valid: lastForm.valid
        }"
      class="ooblee-btn__flat ooblee-btn__flat--primary mt-1"
    >
      {{ 'common.save' | translate }}
    </div>
  </form>

</div>
