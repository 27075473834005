import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShopSelectComponent } from './shop-select.component';
import { StarRatingModule } from '../star-rating/star-rating.module';
import { KissMenuModule, KissSvgIconModule } from '@kiss/components/common';
import { SpinnerModule } from '../spinner/spinner.module';

@NgModule({
  declarations: [ShopSelectComponent],
  imports: [CommonModule, StarRatingModule, KissMenuModule, SpinnerModule, KissSvgIconModule],
  exports: [ShopSelectComponent],
})
export class ShopSelectModule {}
