import { Component, Input } from '@angular/core';

@Component({
  selector: 'ooblee-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
  host: {
    class: 'ooblee-star-rating',
  },
})
export class StarRatingComponent {
  private static id: number = 0;
  @Input() max: number = 5;
  @Input() size: number = 10;

  @Input() set rating(value: number) {
    this._rating = value;
    this._updateRatingArr(+value);
  }

  private _rating: number = 0;
  get rating(): number {
    return this._rating;
  }

  ratingArr: any[] = [];

  constructor() {
    this._updateRatingArr(0);
  }

  private _updateRatingArr(rating: number) {
    this.ratingArr = Array.from(Array(this.max).keys()).map(
      (value: any, i: number) => {
        let fill = 0,
          empty = 100;
        let id = `star-fill-${this.rating}-${i}`;

        if (rating >= i && rating < i + 1) {
          const decimalPoint = rating - Math.floor(rating);
          const percentage = Math.round(decimalPoint * 100);

          fill = percentage;
          empty = 100 - percentage;
        } else if (rating >= i) {
          fill = 100;
          empty = 0;
        }

        return { fill, empty, id };
      }
    );
  }
}
