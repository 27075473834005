import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { KissNavigationSidebar } from './types/kiss-navigation-sidebar.types';
import { Subject } from 'rxjs';
import { SuportService } from 'app/shared/components/support/suport.service';
import { ShopService } from 'app/shared/services/shop/shop.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'kiss-navigation-sidebar',
  templateUrl: './kiss-navigation-sidebar.component.html',
  host: {
    class: 'kiss-navigation-sidebar',
  },
})
export class KissNavigationSidebarComponent implements OnInit, OnDestroy {
  envLinks = environment.links;
  @Input() settings: KissNavigationSidebar;
  @Output() onExpand = new EventEmitter();

  private _unsubscribeAll: Subject<void>;
  constructor(private _shopService: ShopService, private supportServ: SuportService) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  openDialog() {
    this.supportServ.open().then((data) => {
      if (data) {
      }
    });
  }

  toggleExpand() {
    this.settings.expand = !this.settings.expand;
  }

  changeLang() {
    console.log('change lang');
  }
}
