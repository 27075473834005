import { Injectable, OnDestroy } from '@angular/core';
import { KissDialogService } from '@kiss/components/common';
import { KissDialogRef } from '@kiss/components/common/kiss-dialog/helpers/kiss-dialog-ref';
import { ConfirmationPromptComponent } from './confirmation-prompt/confirmation-prompt.component';

@Injectable()
export class PromptService implements OnDestroy {
  confirmationDialogRef: KissDialogRef;

  constructor(private _kissDialogService: KissDialogService) {}

  confirmation(
    message = '',
    btnData = { reject: 'No', confirm: 'Yes' },
    config = {
      dialogClass: 'default-dialog',
    }
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.confirmationDialogRef = this._kissDialogService.open(ConfirmationPromptComponent, {
        data: {
          message,
          btnData,
          onAction: (value: boolean) => {
            resolve(value);
          },
        },
        dialogClass: config?.dialogClass || 'default-dialog',
        exitOnEsc: true,
        exitOnClickOutside: true,
      });
    });
  }

  ngOnDestroy(): void {
    if (this.confirmationDialogRef) this.confirmationDialogRef.destroy();
    this.confirmationDialogRef = null;
  }
}
