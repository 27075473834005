export const parseJsonString = (str: string) => {
  try {
    if (!str) return undefined;

    // JSON PARSE THROWS ERROR IF IT'S NOT A VALID FORMAT, IGORE ERROR AND RETURN UNDEFINED
    return JSON.parse(str);
  } catch (e) {
    return undefined;
  }
};
