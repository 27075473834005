<!-- NAV HEADER -->
<div class="kiss-navigation-sidebar__ooblee-header">
  <ooblee-shop-select [settings]="settings"></ooblee-shop-select>
</div>
<!-- !NAV HEADER -->

<button
  class="nav-expand-btn"
  [ngClass]="{ 'nav-expand-btn__expanded': settings.expand }"
  (click)="toggleExpand()"
>
  <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.26758 5.125L5.74609 1.64648C6.07617 1.3418 6.07617 0.808594 5.74609 0.503906C5.44141 0.173828 4.9082 0.173828 4.60352 0.503906L0.541016 4.56641C0.210938 4.87109 0.210938 5.4043 0.541016 5.70898L4.60352 9.77148C4.75586 9.92383 4.95898 10 5.1875 10C5.39062 10 5.59375 9.92383 5.74609 9.77148C6.07617 9.4668 6.07617 8.93359 5.74609 8.62891L2.26758 5.125ZM7.14258 5.125L10.6211 1.64648C10.9512 1.3418 10.9512 0.808594 10.6211 0.503906C10.3164 0.173828 9.7832 0.173828 9.47852 0.503906L5.41602 4.56641C5.08594 4.87109 5.08594 5.4043 5.41602 5.70898L9.47852 9.77148C9.63086 9.92383 9.83398 10 10.0625 10C10.2656 10 10.4688 9.92383 10.6211 9.77148C10.9512 9.4668 10.9512 8.93359 10.6211 8.62891L7.14258 5.125Z"
      fill="currentColor"
    />
  </svg>
</button>

<!-- NAVIGATION CONTENT -->
<kiss-navigation [folded]="!settings?.expand"></kiss-navigation>

<div class="kiss-navigation-sidebar__ooblee-footer" *ngIf="settings.expand">
<!--  <a class="kiss-navigation-sidebar__ooblee-footer__link" (click)="openDialog()">
    {{ 'common.contact' | translate }}
  </a>-->
  <a class="kiss-navigation-sidebar__ooblee-footer__link" [href]="envLinks.terms" target="_blank"
    >{{ 'common.ooblee' | translate }} {{ 'common.terms' | translate }}</a
  >
  <a
    class="kiss-navigation-sidebar__ooblee-footer__link"
    [href]="envLinks.privacyPolicy"
    target="_blank"
    >{{ 'common.privacyPolicy' | translate }}</a
  >
</div>

<!-- !NAVIGATION CONTENT -->
