import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, switchMap, tap } from 'rxjs/operators';
import {
  BehaviorSubject,
  from,
  lastValueFrom,
  Observable,
  Subscription,
  throwError,
} from 'rxjs';
import { environment } from '../../../environments/environment';
import { KissLoadingBarService } from '@kiss/components/common/kiss-loading-bar/services/kiss-loading-bar.service';
import { UserService } from '../services/user/user.service';
import { ShopService } from '../services/shop/shop.service';
import { CategoryService } from '../services/categories/categories.service';
import { parseJsonString } from '../helpers/parse-json-string';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FirebaseAuthService } from './firebase-auth.service';
import { ToastrService } from 'ngx-toastr';

//const API_AUTH_LOGIN = `${environment.baseUrl}/mobileapi/JsonLogin`;
// const API_AUTH_LOGIN = `${environment.baseUrl}/api/user/v3/login`;
const API_SEND_RESET = `${environment.baseUrl}/mobileapi/JsonForgotPasswordGetCode`;
const API_RESET_PASSWORD = `${environment.baseUrl}/mobileapi/JsonForgotPasswordChangePassword`;

@Injectable({ providedIn: 'root' })
export class AuthenticationService implements OnDestroy {
  private readonly storageName = 'auth_data';

  private _authData: BehaviorSubject<any> = new BehaviorSubject(null);
  private _tokenRefreshSub: Subscription;

  authState: any = null;
  index: number;

  constructor(
    private http: HttpClient,
    private _kissLoadingBarService: KissLoadingBarService,
    private _userService: UserService,
    private _shopService: ShopService,
    private _categoryService: CategoryService,
    private _firebaseAuthService: FirebaseAuthService,
    private _af: AngularFireAuth,
    private _toastr: ToastrService
  ) {
    // const authData = this.getAuthData();
    // if (authData) {
    //   this._authData.next(authData);
    // }

    this._af.authState.subscribe((data) => {
      this._authData.next(data);

      if (data) {
        const user = this._userService.getUserData();

        if (user) {
          this._userService.updateUserData(user);
        } else {
          this.logout();
        }
      }
    });
  }

  getCategories() {
    const activeShop = this._shopService.getShopData();
    return this._categoryService.getCategoryApi({ shopId: activeShop.id });
  }

  login(body: { userName: string; password: string; deviceId: string }): Observable<any> {
    return from(this.firebaseLogin(body));
  }

  async firebaseLogin(body: { userName: string; password: string; deviceId: string }) {
    try {
      const emailAndPass = await this._af.signInWithEmailAndPassword(body.userName, body.password);
      const emailAndPassToken = await emailAndPass.user.getIdToken();
      const connect: any = await lastValueFrom(
        this._firebaseAuthService.connect({
          deviceId: body.deviceId,
          token: emailAndPassToken,
        })
      );
      await this._af.signInWithCustomToken(connect?.result?.token);
      this._userService.setUserFromToken(connect?.result?.profile);
      return connect?.result;
    } catch (err) {
      this._toastr.error(null, err.message);
      throw err;
    }
  }

  getInitialShopData(): Observable<any> {
    return this._shopService.getShopApi().pipe(
      switchMap((response) => {
        const firstShopActive = response[0];
        return this._shopService.getShop(firstShopActive?.id);
      }),
      tap((response) => {
        if (response) this._shopService.saveActiveShop(response);
      }),
      catchError((error) => {
        this._removeLocalData();
        return throwError(() => error);
      })
    );
  }

  setActiveShop(shopId: number): Observable<any> {
    return this._shopService.getShop(shopId).pipe(
      tap((response) => {
        if (response) this._shopService.saveActiveShop(response);
      }),
      catchError((error) => {
        this._removeLocalData();
        return throwError(() => error);
      })
    );
  }

  sendResetCode(body: any) {
    this._kissLoadingBarService.show();
    return this.http.post(API_SEND_RESET, body);
  }

  resetPassword(body) {
    this._kissLoadingBarService.show();
    return this.http.post(API_RESET_PASSWORD, body);
  }

  async logout() {
    const user = await this._af.currentUser;
    if (user) {
      return await this._af.signOut().then(() => {
        this.logoutLocally();
        return true;
      });
    }
    return;
  }

  logoutLocally() {
    const authData = this.getAuthData();
    const shopData = this._shopService.getShopData();
    const userData = this._userService.getUserData();
    if (authData || userData || shopData) {
      this._removeLocalData();
    }
  }

  isAuthenticated(): boolean {
    return !!this._authData.value;
  }

  updateAuthData(data: any) {
    localStorage.setItem(this.storageName, JSON.stringify(data));
    this._authData.next(data);
  }

  getAuthData(): any {
    const authData = localStorage.getItem(this.storageName);
    return parseJsonString(authData);
  }

  clearAuthData() {
    localStorage.removeItem(this.storageName);
    this._authData.next(undefined);
  }

  private _removeLocalData() {
    this._userService.clearUserData();
    this._shopService.clearShopData();
    this.clearAuthData();
  }

  ngOnDestroy(): void {
    //Prevent memory leak
    if (this._tokenRefreshSub) {
      this._tokenRefreshSub.unsubscribe();
      this._tokenRefreshSub = null;
    }
  }
}
