<button
  class="kiss-datepicker__btn kiss-datepicker__year__item"
  *ngFor="let year of years"
  [ngClass]="{
    'kiss-datepicker__year__item--current': currentYear == year.getFullYear()
  }"
  (click)="onYearChange($event, year)"
>
  {{ year | date: 'yyyy' }}
</button>
