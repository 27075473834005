import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogInComponent } from './log-in.component';
import { RouterModule } from '@angular/router';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UnAuthGuard } from '../../shared/guards/unauth.guard';
import { HeaderModule } from '../header/header.module';
import { FooterModule } from '../footer/footer.module';
import { TranslateModule } from 'app/shared/services/translate/translate.module';

const routes = [
  {
    path: 'login',
    component: LogInComponent,
    canActivate: [UnAuthGuard],
    data: {
      layoutConfig: {
        layoutType: 'vertical-classic',
        verticalClassicConfig: {
          navigationSidebar: {
            enabled: false,
          },
          toolbar: {
            enabled: false,
          },
        },
      },
    },
  },
];

@NgModule({
  declarations: [LogInComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    HeaderModule,
    FooterModule,
    TranslateModule,
  ],
  providers: [UnAuthGuard],
  exports: [],
})
export class LogInModule {}
