import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { KissLoaderModule } from '../kiss-loader/kiss-loader.module';
import { KissSplashScreenComponent } from './kiss-splash-screen.component';
import { KissSplashScreenService } from './services/kiss-splash-screen.service';

@NgModule({
  declarations: [KissSplashScreenComponent],
  imports: [CommonModule, KissLoaderModule],
  exports: [KissSplashScreenComponent],
  providers: [KissSplashScreenService],
})
export class KissSplashScreenModule {}
