import { Component, OnDestroy } from '@angular/core';
import { KissDialogConfig } from '@kiss/components/common/kiss-dialog/helpers/kiss-dialog-config';
import { KissDialogRef } from '@kiss/components/common/kiss-dialog/helpers/kiss-dialog-ref';

@Component({
  selector: 'app-confirmation-prompt',
  templateUrl: './confirmation-prompt.component.html',
  styleUrls: ['./confirmation-prompt.component.scss'],
})
export class ConfirmationPromptComponent implements OnDestroy {
  public message: string = '';
  public btnData: { reject: string; confirm: string };

  constructor(private ref: KissDialogRef, private config: KissDialogConfig) {
    this.message = this.config.data.message;
    this.btnData = this.config.data.btnData;
  }

  onConfirm() {
    this.config.data.onAction(true);
    this.ref.destroy();
  }

  onReject() {
    this.config.data.onAction(false);
    this.ref.destroy();
  }

  ngOnDestroy(): void {
    if (this.ref) {
      this.config.data.onAction(false);
      this.ref.destroy();
    }
  }
}
