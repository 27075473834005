import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class KissSplashScreenService {
  _onVisibilityChange: BehaviorSubject<boolean>;

  constructor() {
    this._init();
  }

  private _init() {
    this._onVisibilityChange = new BehaviorSubject(false);
  }

  show() {
    this._onVisibilityChange.next(true);
  }

  hide() {
    this._onVisibilityChange.next(false);
  }
}
