import { DatePipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { kissAnimations } from '@kiss/animations';
import { KissIconPostDirective, KissIconPreDirective } from '@kiss/directives/kiss-icon-pre-post';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { KissDatepickerDay } from './kiss-datepicker-day/kiss-datepicker-day';
import { KissDatepickerTime } from './kiss-datepicker-time/kiss-datepicker-time';
import { KissDatepickerSettings } from './kiss-datepicker-settings/kiss-datepicker-settings';
import { KissDatepickerSelectionMode } from './types/kiss-datepicker-selection-mode';
import { KissDatepickerTimestamp } from './types/kiss-datepicker-timestamp.type';
import { KissDatepickerViews } from './types/kiss-datepicker-views.type';

@Component({
  selector: 'kiss-datepicker',
  templateUrl: './kiss-datepicker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: kissAnimations,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => KissDatepickerComponent),
      multi: true,
    },
  ],
  host: {
    class: 'kiss-datepicker',
    '[attr.tabindex]': 'tabindex',
    '(focus)': 'onFocus()',
    '(blur)': 'onBlur()',
  },
})
export class KissDatepickerComponent
  implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy
{
  // -----------------------------------------------------------------------------------------------------
  // @ BUILT IN
  // -----------------------------------------------------------------------------------------------------

  /**
   * Optional value that determents input
   */
  @Input() set selectionMode(value: any) {
    if (value !== 'date' && value !== 'range') return;
    this._selectionMode = value;
  }

  private _selectionMode: KissDatepickerSelectionMode = 'datepicker';
  get selectionMode(): KissDatepickerSelectionMode {
    return this._selectionMode;
  }

  /**
   * Holds the current value of the control
   */
  @Input() set value(value: any) {
    let newValue: Date | Date[];

    //datepicker
    switch (this.selectionMode) {
      case 'range':
        newValue = this._setRangeValue(value);
        break;
      default:
        newValue = this._setDatepickerValue(value);
    }

    // appy the new value
    if (newValue !== this._value) {
      this._value = newValue;
      this._preselectValue(this._value);
    }

    if (this.datepickerSettings.time?.enabled) {
      this._setTimeSettings();
    }
  }

  private _value: any;
  get value() {
    return this._value;
  }

  /**
   * Invoked when the model has been changed
   */
  onChange: (_: any) => void = (_: any) => {};

  /**
   * Invoked when the model has been touched
   */
  onTouched: () => void = () => {};

  /**
   * Invoked when the model is disabled
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this._renderer.setProperty(this._elRef.nativeElement, 'disabled', isDisabled);
  }

  /**
   * Method that is invoked on an update of a model.
   */
  updateChanges() {
    this.onChange(this.value);
  }

  ///////////////
  // OVERRIDES //
  ///////////////

  /**
   * Writes a new item to the element.
   * @param value the value
   */
  writeValue(value): void {
    this.value = value;
    this.updateChanges();
  }

  /**
   * Registers a callback function that should be called when the control's value changes in the UI.
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Registers a callback function that should be called when the control receives a blur event.
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ CUSTOM
  // -----------------------------------------------------------------------------------------------------
  @ViewChild('container') container: ElementRef;
  @ViewChild('viewContainerRef') viewContainerRef: ElementRef;
  @ViewChild('tabTrap') tabTrap: ElementRef;

  /**
   * Current date
   */
  today = new Date();

  /**
   * Date that is used to navigate to new years/month/day views
   */
  viewDate = new Date();

  /**
   * Date of the info displayed in the header
   */
  viewInfoDate = new Date();

  /**
   * Current selected value
   */
  selected: Date | Date[];

  /**
   * Views
   *
   * `day` | `month` | `year` | `time`
   *
   * @type KissDatepickerViews
   */
  view: KissDatepickerViews = 'day';

  /**
   * LABEL
   */
  label: string = '';

  /**
   * Settings used
   */
  timeSettings: KissDatepickerTime;

  /**
   * datepicker placeholder
   */
  @Input() placeholder: string = '';

  /**
   * Set the attr.tabIndex
   */
  @Input() tabindex: string = '0';

  /**
   * Adds a class and controls if datepicker is disabled
   */
  @Input() @HostBinding('class.kiss-disabled') set disabled(value: any) {
    this._disabled = value || value === '';

    this.tabindex = this.disabled ? '' : '0';
  }

  private _disabled: boolean = false;
  get disabled() {
    return this._disabled;
  }

  //OPEN
  @HostBinding('class.kiss-datepicker--open') private _open = false;
  @Input() set open(value: boolean) {
    this._setupOpen(value);
  }

  get open() {
    return this._open;
  }

  //focused
  @Input() set focus(value: any) {
    this._focus = value || value === '';
    this._renderer.setProperty(this._elRef.nativeElement, 'focus', this._focus);
  }

  @Input() dropdownClass: string = '';

  private _datepickerSettings: KissDatepickerSettings = new KissDatepickerSettings();

  /**
   * Datepicker Settings
   *
   */
  @Input() set datepickerSettings(value: KissDatepickerSettings) {
    this._datepickerSettings =
      value instanceof KissDatepickerSettings ? value : new KissDatepickerSettings(value);

    this.view = this.datepickerSettings.initialView;
  }

  get datepickerSettings(): KissDatepickerSettings {
    return this._datepickerSettings;
  }

  @HostBinding('class.kiss-datepicker--focus') private _focus = false;
  get focus() {
    return this._focus;
  }

  /**
   * Emits value on change event
   */
  @Output() onDateChange: EventEmitter<{
    value: Date | Date[];
  }> = new EventEmitter();

  @ContentChild(KissIconPreDirective) preIconDirective: KissIconPreDirective;
  @ContentChild(KissIconPostDirective) postIconDirective: KissIconPostDirective;

  private _selectedChange: Subject<Date | Date[]>;
  private _unsubscribeAll: Subject<void>;
  private _rendererKeydown: any;
  constructor(
    private _cdr: ChangeDetectorRef,
    private _renderer: Renderer2,
    private _elRef: ElementRef,
    private datePipe: DatePipe,
    @Inject(LOCALE_ID) private locale: string
  ) {
    this._unsubscribeAll = new Subject();
    this._selectedChange = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * OnInit
   */
  ngOnInit(): void {
    this.view = this.datepickerSettings.initialView;

    this._selectedChange.pipe(takeUntil(this._unsubscribeAll)).subscribe((value: Date) => {
      switch (this.selectionMode) {
        case 'range':
          this._rangeSelectionChange(value);
          break;
        default:
          this._datepickerSelectionChange(value);
      }
    });
  }

  /**
   * AfterViewInit
   */
  ngAfterViewInit(): void {
    if (this.datepickerSettings.time?.enabled && !this.timeSettings) {
      this._setTimeSettings();
    }
  }

  /**
   * OnDestroy
   */
  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();

    this._removeKeydownListener();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  private _setTimeSettings() {
    this.timeSettings = new KissDatepickerTime({
      selected: this.selected,
      viewInfoDate: this.viewInfoDate,
      selectionMode: this.selectionMode,
    });
  }

  /**
   * On selection change in datepicker mode
   * @param value
   */
  private _datepickerSelectionChange(value: Date) {
    this._updateDatepickerSelected(value);

    if (this.datepickerSettings.time?.enabled) return;
    this._saveAndEmitDatepickerValue();
  }

  /**
   * Save the value , update label, emit event for datepicker mode
   */
  private _saveAndEmitDatepickerValue() {
    this._checkAndUpdateTime();

    this._updateDatepickerLabel();

    this.writeValue(this.selected);

    this.onDateChange.next({
      value: this.value,
    });

    this.toggleClose();
  }

  /**
   * On selection change in range mode
   * @param value
   */
  private _rangeSelectionChange(value: Date) {
    this._updateRangeSelected(value);

    if (this.datepickerSettings.time?.enabled) return;
    this._saveAndEmitRangeValue();
  }

  /**
   * Save the value , update label, emit event for range mode
   */
  private _saveAndEmitRangeValue() {
    this._checkAndUpdateTime();

    this._updateRangeLabel();

    const tmpSelected = this.selected as Date[];

    if (tmpSelected?.length !== 2 && tmpSelected?.length) return;

    this.writeValue(tmpSelected);

    this.onDateChange.next({
      value: this.value,
    });

    this.toggleClose();
  }

  /**
   * Method that runs when open input is changed
   * @param boolean
   */
  private _setupOpen(value: boolean) {
    this._open = value;

    if (this._open) {
      this.toggleOpen();
    } else {
      this.toggleClose();
    }
  }

  private _listenOpenEvents() {
    //listen for keydown on the open menu
    this._rendererKeydown = this._renderer.listen(window, 'keydown', (event) => {
      const container = this.container?.nativeElement;
      const contains = container && container.contains(document.activeElement);

      if (event.key === 'Escape' && contains) {
        this.toggleClose();

        this._setFocusOnClose();
        return;
      }

      const tabTrap = this.tabTrap?.nativeElement;

      if (tabTrap === document.activeElement) {
        this.toggleClose();

        this._setFocusOnClose();
      }
    });
  }

  private _setFocus() {
    this._focus = true;
  }

  private _setBlur() {
    this._focus = false;
    this.onTouched();
  }

  private _setFocusOnClose() {
    this._elRef.nativeElement.focus();

    this._setFocus();
  }

  private _setFocusOnOpen() {
    setTimeout(() => {
      let child = this.container?.nativeElement?.firstChild;

      if (child?.focus) {
        child.focus();
      }
    });
  }

  private _removeKeydownListener() {
    if (this._rendererKeydown) this._rendererKeydown();
  }

  private _updateDatepickerLabel() {
    if (this.selected) {
      const format = this._formatLabel();

      this.label = this.datePipe.transform(this.selected as Date, format, this.locale);
    } else {
      this.label = '';
    }
  }

  private _updateRangeLabel() {
    const selectedCpy = this.selected as Date[];

    if (selectedCpy?.length == 2) {
      const format = this._formatLabel();

      const firstDate = this.datePipe.transform(selectedCpy[0], format, this.locale);

      const secondDate = this.datePipe.transform(selectedCpy[1], format, this.locale);

      this.label = `${firstDate} - ${secondDate}`;
    } else {
      this.label = '';
    }
  }

  /**
   * Format selected labels
   * @returns
   */
  private _formatLabel() {
    if (!this.datepickerSettings.time?.enabled) {
      return this.datepickerSettings.labelFormats.date;
    } else {
      return (
        this.datepickerSettings.labelFormats.date + ' ' + this.datepickerSettings.labelFormats.time
      );
    }
  }

  /**
   * Set value for the datepicker mode
   */
  private _setDatepickerValue(value: any) {
    if (this._isValidDate(value)) {
      const tmpValue = value instanceof Date ? value.getTime() : value;
      return new Date(tmpValue);
    } else {
      return value;
    }
  }

  /**
   * Set value for the range mode
   */
  private _setRangeValue(value) {
    // check if it's a valid date
    const newDateArr = [];
    if (Array.isArray(value)) {
      //check validity for all dates
      value.forEach((item) => {
        if (this._isValidDate(item)) {
          const tmpValue = item instanceof Date ? item.getTime() : item;
          newDateArr.push(new Date(tmpValue));
        } else {
          newDateArr.push(item);
        }
      });

      //sort dates
      const sortedDates = newDateArr.sort((a: any, b: any) => {
        if (a instanceof Date && b instanceof Date) {
          return a.getTime() - b.getTime();
        }

        return 1;
      });

      return sortedDates;
    } else {
      return newDateArr;
    }
  }

  private _preselectValue(value: any) {
    switch (this.selectionMode) {
      case 'range':
        this._preselectRangeValue(value);
        break;
      default:
        this._preselectDatepickerValue(value);
    }

    this._cdr.markForCheck();
  }

  /**
   * Set selected value and update the label for datepicker mode
   * @param value
   */
  private _preselectDatepickerValue(value: any) {
    if (!value) {
      this.selected = undefined;
      this._updateDatepickerLabel();
    } else if (this._isValidDate(value)) {
      this.selected = new Date(this._clone(value));
      this._updateDatepickerLabel();
    } else {
      console.warn(`Invalid date: ${value}`);
    }
  }

  /**
   * Set selected value and update the label for range mode
   * @param value
   */
  private _preselectRangeValue(value: any) {
    const allDatesAreValid = value && value?.every((date) => this._isValidDate(date));

    if (!value?.length) {
      this.selected = [];
      this._updateRangeLabel();
    } else if (allDatesAreValid) {
      const tmpValue = value.map((date) => new Date(this._clone(date)));
      this.selected = [...tmpValue];
      this._updateRangeLabel();
    } else {
      console.warn(`Invalid date: ${value}`);
    }
  }

  /**
   * Update selected value for datepicker mode
   * @param date
   */
  private _updateDatepickerSelected(date: Date) {
    const isSameDate = this._compareSelectedDate(date);
    this.selected = isSameDate ? undefined : this._clone(date);
  }

  /**
   * Check if the passed date's month, day, year match the selected
   * @param date
   * @returns
   */
  private _compareSelectedDate(date: Date) {
    const dateCpy = date ? this._clone(date) : undefined;
    const selectedCpy = this.selected ? this._clone(this.selected as Date) : undefined;

    dateCpy?.setHours(0, 0, 0, 0);
    selectedCpy?.setHours(0, 0, 0, 0);

    return dateCpy?.toLocaleString() == selectedCpy?.toLocaleString();
  }

  /**
   * Compare selected dates with the passed date and tako appropriate action (remove or add)
   * @param date
   */
  private _updateRangeSelected(date: Date) {
    const dateCpy = date ? this._clone(date) : undefined;

    if (!this.datepickerSettings.time?.enabled) {
      dateCpy?.setHours(0, 0, 0, 0);
    }

    const containsSameDate = this._findSameDate(dateCpy);

    if (containsSameDate) {
      this.selected = (this.selected as Date[])?.filter((item) => {
        const itemCpy = new Date(this._clone(item));

        if (!this.datepickerSettings.time?.enabled) {
          itemCpy.setHours(0, 0, 0, 0);
        }

        return new Date(containsSameDate)?.toLocaleString() != itemCpy?.toLocaleString();
      });
    } else {
      let tmpSelected = [...(this.selected as Date[])] || [];

      if (!tmpSelected?.length || tmpSelected?.length >= 2) {
        tmpSelected = [this._clone(date)];
      } else {
        tmpSelected.push(this._clone(date));
      }

      this.selected = tmpSelected?.sort((a, b) => a.getTime() - b.getTime());
    }
  }

  /**
   * Check if the passed date can be found inside selected dates
   * @param date
   * @returns
   */
  private _findSameDate(date: Date): Date {
    const selectedCpy =
      (this.selected as Date[])?.map((item) => {
        const itemCpy = this._clone(item);

        if (!this.datepickerSettings.time?.enabled) {
          itemCpy.setHours(0, 0, 0, 0);
        }

        return itemCpy;
      }) || [];

    return selectedCpy?.find((item) => date == item);
  }

  /**
   * Updates the view info section labels
   * @param date
   */
  private _updateViewInfoDate(date: Date) {
    this.viewInfoDate = this._clone(date);
  }

  private _updateDatepickerTimeValue(timestamp: KissDatepickerTimestamp[]) {
    this.timeSettings.updateValueByTimestamp(timestamp);
  }

  /**
   * Resets the datepicker view
   */
  private _resetView() {
    this.view = this.datepickerSettings.initialView;
  }

  /**
   * Change viewDate to selected date value
   */
  private _setViewDateAsSelected() {
    switch (this.selectionMode) {
      case 'range':
        this._setRangeViewDateAsSelected();
        break;
      default:
        this._setDatepickerViewDateAsSelected();
    }
  }

  /**
   * Set viewDate as selected for datepicker mode
   */
  private _setDatepickerViewDateAsSelected() {
    if (this.selected) {
      this.viewDate = this._clone(this.selected as Date);
    } else {
      this.viewDate = new Date();
    }

    this._updateViewInfoDate(this.viewDate);
  }

  /**
   * Set viewDate as selected for datepicker range
   */
  private _setRangeViewDateAsSelected() {
    const selectedValue = this.selected as Date[];

    if (selectedValue?.length) {
      if (selectedValue[1]) {
        this.viewDate = this._clone(selectedValue[1]);
      } else {
        this.viewDate = this._clone(selectedValue[0]);
      }
    } else {
      this.viewDate = new Date();
    }

    this._updateViewInfoDate(this.viewDate);
  }

  /**
   * Parse the value passed and check if it's NaN
   * @param date
   * @returns boolean
   */
  private _isValidDate(date: any) {
    return !isNaN(Date.parse(date));
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Close select
   */
  toggleClose() {
    this._open = false;
    this._setBlur();

    this._removeKeydownListener();

    this._resetView();

    this._cdr.markForCheck();
  }

  /**
   * Open select
   */
  toggleOpen() {
    if (this.disabled) return;

    this._open = true;

    this._setFocus();
    this._setFocusOnOpen();
    this._setViewDateAsSelected();
    this._listenOpenEvents();

    if (this.datepickerSettings.time?.enabled) {
      this._setTimeSettings();
    }

    this._cdr.markForCheck();
  }

  /**
   * If element is not focused on
   */
  onFocus() {
    if (this.disabled) return;
    this._setFocus();
  }

  /**
   * If element is blured
   */
  onBlur() {
    if (this.open) return;
    this._setBlur();
  }

  /**
   * Opens the dropdown when kiss-datepciker is clicked
   */
  onDatepickerClick() {
    this.toggleOpen();
  }

  onContainerClick() {
    this.toggleClose();
  }

  onMonthViewClick() {
    if (this.view === 'month') {
      this.view = 'day';
    } else {
      this.view = 'month';
    }
  }

  onTimeViewClick() {
    if (this.view === 'time') {
      this.view = 'day';
    } else {
      this.view = 'time';
    }
  }

  onYearViewClick() {
    if (this.view === 'year') {
      this.view = 'day';
    } else {
      this.view = 'year';
    }
  }

  /**
   * Action taken when the previous button is clicked
   * @returns
   */
  onClickPrev() {
    if (this.view === 'year') {
      this._onClickPrevYear();
    }

    if (this.view === 'day') {
      this._onClickPrevDay();
    }
  }

  /**
   * When the view year is selected and previous button is clicked
   * @returns
   */
  private _onClickPrevYear() {
    const year = this.viewDate.getFullYear();
    const newYear = year - this.datepickerSettings.year.generateYears;

    if (newYear < 0) return;
    const newViewDate = this.viewDate.setFullYear(newYear);
    this.viewDate = new Date(newViewDate);
  }

  /**
   * When the view day is selected and previous button is clicked
   */
  private _onClickPrevDay() {
    const newMonth = this._clone(this.viewDate);
    this.viewDate = new Date(
      newMonth.getFullYear(),
      newMonth.getMonth() - 1,
      1,
      newMonth.getHours(),
      newMonth.getMinutes(),
      newMonth.getSeconds(),
      newMonth.getMilliseconds()
    );

    this._updateViewInfoDate(this.viewDate);
  }

  /**
   * Action taken when the next button is clicked
   * @returns
   */
  onClickNext() {
    if (this.view === 'year') {
      this._onClickNextYear();
    }

    if (this.view === 'day') {
      this._onClickNextDay();
    }
  }

  /**
   * When the view year is selected and next button is clicked
   */
  private _onClickNextYear() {
    const year = this.viewDate.getFullYear();
    const newYear = year + this.datepickerSettings.year.generateYears;
    const newViewDate = this.viewDate.setFullYear(newYear);
    this.viewDate = new Date(newViewDate);
  }

  /**
   * When the view day is selected and next button is clicked
   */
  private _onClickNextDay() {
    const newMonth = this._clone(this.viewDate);
    this.viewDate = new Date(
      newMonth.getFullYear(),
      newMonth.getMonth() + 1,
      1,
      newMonth.getHours(),
      newMonth.getMinutes(),
      newMonth.getSeconds(),
      newMonth.getMilliseconds()
    );

    this._updateViewInfoDate(this.viewDate);
  }

  onMonthValueChange(date: Date) {
    this.viewDate = this._clone(date);
    this._updateViewInfoDate(this.viewDate);
    this._resetView();
  }

  onYearValueChange(date: Date) {
    this.viewDate = this._clone(date);
    this._updateViewInfoDate(this.viewDate);
    this._resetView();
  }

  onDateValueChange(date: KissDatepickerDay) {
    const tmpDate = this._clone(date.date);
    this._selectedChange.next(tmpDate);
  }

  onTimeValueChange(timestamp: KissDatepickerTimestamp[]) {
    this._updateDatepickerTimeValue(timestamp);
  }

  /**
   * Create a date copy
   * @param date
   * @returns
   */
  private _clone(date: Date): Date {
    if (date instanceof Date) {
      return new Date(date.getTime());
    }

    if (this._isValidDate(date)) {
      return new Date(date);
    }

    return date;
  }

  /**
   * Update selected dates times
   * @returns
   */
  private _checkAndUpdateTime() {
    if (
      !this.datepickerSettings.time?.enabled ||
      (this.datepickerSettings.day.enabled && !this.selected)
    )
      return;

    switch (this.selectionMode) {
      case 'range':
        this._updateTimeRange();
        break;

      default:
        this._updateTimeDatepicker();
    }
  }

  private _updateTimeDatepicker() {
    const { hours, minutes, seconds, milliseconds } = this.timeSettings.value[0];

    if (!this.datepickerSettings.day.enabled && !this.selected) {
      this.selected = this._clone(this.viewDate);
    }

    (this.selected as Date).setHours(+hours, +minutes, +seconds, +milliseconds);
  }

  private _updateTimeRange() {
    if (!this.datepickerSettings.day.enabled && !this.selected) {
      this.selected = [this._clone(this.viewDate), this._clone(this.viewDate)];
    }

    (this.selected as Date[]).forEach((date: Date, index: number) => {
      const { hours, minutes, seconds, milliseconds } = this.timeSettings.value[index];
      date.setHours(+hours, +minutes, +seconds, +milliseconds);
    });
  }

  onSave() {
    switch (this.selectionMode) {
      case 'range':
        this._saveAndEmitRangeValue();
        break;
      default:
        this._saveAndEmitDatepickerValue();
    }
  }

  onCancel() {
    this.toggleClose();
  }
}
