import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { kissAnimations } from '@kiss/animations';

@Component({
  selector: 'kiss-splash-screen',
  templateUrl: './kiss-splash-screen.component.html',
  host: {
    class: 'kiss-splash-screen',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [kissAnimations],
})
export class KissSplashScreenComponent {
  @Input() visible = true;
}
