import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarLanguagesComponent } from './toolbar-languages.component';
import { KissSelectModule } from '@kiss/components/common';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [ToolbarLanguagesComponent],
  imports: [CommonModule, KissSelectModule, ReactiveFormsModule],
  exports: [ToolbarLanguagesComponent],
})
export class ToolbarLanguagesModule {}
