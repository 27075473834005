import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from, throwError } from 'rxjs';

import { switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { DISABLE_TOKEN_INTERCEPTOR } from '../helpers/disable-token-interceptor';
import { AngularFireAuth } from '@angular/fire/compat/auth';

class Config {
  public static logoutErrorCodes = [401, 403];
  public static tokenName = 'Authorization';
}

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private _af: AngularFireAuth
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this._handleAccess(req, next));
  }

  private _handleAccess(req: HttpRequest<any>, next: HttpHandler) {
    const skipAuth = req.context.get(DISABLE_TOKEN_INTERCEPTOR);

    return this._af.idToken.pipe(
      take(1),
      switchMap((token) => {
        if (token || skipAuth) {
          // If a token is available, add it to the request headers
          req = req.clone({
            setHeaders: {
              [Config.tokenName]: `Bearer ${token}`,
            },
          });
        }

        return next.handle(req);
      })
    );
  }

  private _handleAuthError(error: HttpErrorResponse) {
    let errorMsg = '';
    if (error.error instanceof ErrorEvent) {
      //console.log('This is client side error');
      errorMsg = `Error: ${error.error.message}`;
    } else {
      if (Config.logoutErrorCodes.includes(error.status)) {
        this.authService.logout();
        this.router.navigate(['/login']);
      }
    }

    return throwError(() => new Error(errorMsg));
  }
}
