import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { KissLoadingBarService } from '@kiss/components/common';
import { AuthenticationService } from 'app/shared/auth/authentication.service';
import { DeviceService } from 'app/shared/services/device/device.service';
import { TranslatePipe } from 'app/shared/services/translate/translate.pipe';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

@Component({
  selector: 'ooblee-login-code',
  templateUrl: './login-code.component.html',
  styleUrls: ['./login-code.component.scss'],
})
export class LoginCodeComponent implements OnInit, OnDestroy {
  form: FormGroup;
  submitted = false;
  rememberMe = false;
  alert = false;
  destroy$: Subject<void> = new Subject<void>();
  email: string | null = null;
  shopName: string | null = null;
  shopId: number | null = null;

  constructor(
    private _fb: FormBuilder,
    private _router: Router,
    private authService: AuthenticationService,
    private toastr: ToastrService,
    private _kissLoadingBarService: KissLoadingBarService,
    private _deviceService: DeviceService,
    private _translatePipe: TranslatePipe,
    private route: ActivatedRoute
  ) {
    this._kissLoadingBarService.show();
    const resolvedData = this.route.snapshot.data['validParams'];

    this.email = resolvedData.email;
    this.shopName = resolvedData.shopName;
    this.shopId = resolvedData.shopId;
  }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.form = this._fb.group({
      password: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(50)]],
      deviceId: [this.generateUUIDv4()],
    });
  }

  generateUUIDv4(): string {
    return this._deviceService.generateUUIDv4();
  }

  submit() {
    if (this.form.invalid) {
      const toastNotif = this._translatePipe.transform('notifs.allFieldsRequired');
      this.toastr.info(null, toastNotif);
    } else {
      const postData = { ...this.form.getRawValue() };

      const formData = {
        userName: this.email,
        password: postData.password,
        deviceId: postData.deviceId,
      };

      this.authService
        .login(formData)
        .subscribe(() => {
          this._kissLoadingBarService.show();
          this.authService
            .setActiveShop(this.shopId)
            .subscribe({
              next: () => {
                this._router.navigate(['landing']);
                const toastNotif = this._translatePipe.transform('logIn.successfullyLoggedIn');
                this.toastr.success(null, toastNotif);
              },
              error: () => {
                const error = this._translatePipe.transform('notifs.noShopsFound');
                this.toastr.error(null, error);
              },
            })
            .add(() => {
              this._kissLoadingBarService.hide();
            });
        })
        .add(() => {
          this._kissLoadingBarService.hide();
        });
    }
  }

  onTogglePass(elPass: HTMLInputElement) {
    elPass.type = elPass.type === 'password' ? 'text' : 'password';
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
