import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AuthenticationService } from '../auth/authentication.service';
import { DISABLE_ERROR_HANDLING } from '../helpers/disable-error-handling';

const RELOAD_STATUS = [401, 403];
const EXCEPTION_URLS = ['/login', '/forgot-password'];

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  defaultError = 'An error has occured!';
  constructor(
    private _router: Router,
    private _toastr: ToastrService,
    private _auth: AuthenticationService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //IGNORE ERROR
    if (this._disableErrorHandling(request)) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {
        if (RELOAD_STATUS.includes(response.status) && !this._includesUrl(this._router.url)) {
          this._removeUserInfo();
          window.location.reload();
        }

        if (response.error.errors && response.error.errors.length) {
          this._toastr.error(null, response.error.errors[0]);
        } else {
          const possibleErrorContent =
            response.error.error_description ||
            response.error.error ||
            response.error.message ||
            response.error.detail ||
            response.message;

          let errorContent = possibleErrorContent || this.defaultError;

          if (errorContent && typeof errorContent !== 'string') {
            errorContent = JSON.stringify(errorContent);
          }

          this._toastr.error(null, errorContent);
        }

        return throwError(() => response.error);
      })
    );
  }

  private async _removeUserInfo() {
    if (this._auth.isAuthenticated()) {
      await this._auth.logout();
      this._toastr.warning('Please log in!');
    }
  }

  private _includesUrl(fullUrl) {
    return EXCEPTION_URLS.some((url) => fullUrl.includes(url));
  }

  private _disableErrorHandling(request: HttpRequest<any>) {
    return request.context.get(DISABLE_ERROR_HANDLING);
  }
}
