import { Inject, Injectable } from '@angular/core';
import { KissBreadcrumbsSettings } from '../kiss-breadcrumb-settings.type';

//@Injectable({ providedIn: 'root' })
@Injectable()
export class KissBreadcrumbsSettingsService {
  private _settings: KissBreadcrumbsSettings;
  constructor(@Inject('kissBreadcrumbSettings') private settings: any) {
    this._settings = this.settings ?? {};
  }

  getSettings(): KissBreadcrumbsSettings {
    return this._settings;
  }
}
