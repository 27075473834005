<div class="kiss-paginator__results" *ngIf="showResults">
  <ng-container *ngIf="!resultsDirective">
    <span>{{ currentSize }} od {{ total }} rezultata</span>
    <select
      class="kiss-paginator__page-size-select"
      *ngIf="pageSizes.length"
      #pageSizeSelect
      (change)="onPageSizeSelect(pageSizeSelect.value)"
    >
      <option
        *ngFor="let pageOption of pageSizes"
        [value]="pageOption"
        [selected]="pageOption === pageSize"
      >
        {{ pageOption }}
      </option>
    </select>
  </ng-container>

  <ng-container
    *ngIf="resultsDirective"
    [ngTemplateOutlet]="resultsDirective.templateRef"
    [ngTemplateOutletContext]="{
      $implicit: currentSize,
      currentSize: currentSize,
      total: total,
      pageOptions: pageOptions,
      pageSize: pageSize
    }"
  >
  </ng-container>
</div>
<div class="kiss-paginator__counter-container">
  <button
    class="kiss-paginator__item kiss-paginator__arrow-left"
    (click)="onFirstClick()"
    [disabled]="currentPage <= 1"
  >
    <svg
      *ngIf="!preIconDirective"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 7C0.447715 7 0 7.44772 0 8C0 8.55228 0.447715 9 1 9V7ZM15 8L15.7071 8.70711C16.0976 8.31658 16.0976 7.68342 15.7071 7.29289L15 8ZM8.70711 0.292893C8.31658 -0.0976311 7.68342 -0.0976311 7.29289 0.292893C6.90237 0.683417 6.90237 1.31658 7.29289 1.70711L8.70711 0.292893ZM7.29289 14.2929C6.90237 14.6834 6.90237 15.3166 7.29289 15.7071C7.68342 16.0976 8.31658 16.0976 8.70711 15.7071L7.29289 14.2929ZM1 9H15V7H1V9ZM7.29289 1.70711L14.2929 8.70711L15.7071 7.29289L8.70711 0.292893L7.29289 1.70711ZM14.2929 7.29289L7.29289 14.2929L8.70711 15.7071L15.7071 8.70711L14.2929 7.29289Z"
        fill="currentColor"
      />
    </svg>

    <ng-template
      *ngIf="preIconDirective"
      [ngTemplateOutlet]="preIconDirective.templateRef"
      [ngTemplateOutletContext]="{ disabled }"
    ></ng-template>
  </button>

  <button
    *ngFor="let page of visiblePages"
    class="kiss-paginator__item kiss-paginator__number"
    [ngClass]="{ 'kiss-paginator__active': currentPage === page }"
    (click)="onPageClick(page)"
  >
    {{ page }}
  </button>

  <button
    class="kiss-paginator__item kiss-paginator__arrow-right"
    (click)="onLastClick()"
    [disabled]="currentPage >= totalPages"
  >
    <svg
      *ngIf="!postIconDirective"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 7C0.447715 7 0 7.44772 0 8C0 8.55228 0.447715 9 1 9V7ZM15 8L15.7071 8.70711C16.0976 8.31658 16.0976 7.68342 15.7071 7.29289L15 8ZM8.70711 0.292893C8.31658 -0.0976311 7.68342 -0.0976311 7.29289 0.292893C6.90237 0.683417 6.90237 1.31658 7.29289 1.70711L8.70711 0.292893ZM7.29289 14.2929C6.90237 14.6834 6.90237 15.3166 7.29289 15.7071C7.68342 16.0976 8.31658 16.0976 8.70711 15.7071L7.29289 14.2929ZM1 9H15V7H1V9ZM7.29289 1.70711L14.2929 8.70711L15.7071 7.29289L8.70711 0.292893L7.29289 1.70711ZM14.2929 7.29289L7.29289 14.2929L8.70711 15.7071L15.7071 8.70711L14.2929 7.29289Z"
        fill="currentColor"
      />
    </svg>

    <ng-template
      *ngIf="postIconDirective"
      [ngTemplateOutlet]="postIconDirective.templateRef"
      [ngTemplateOutletContext]="{ disabled }"
    ></ng-template>
  </button>
</div>
