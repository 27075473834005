import { ActivatedRouteSnapshot } from "@angular/router";

export class KissBreadcrumb {
  id?: number;
  label: string;
  url?: string;
  redirectUrl?: string;
  queryParams?: any;
  routeQueryParams?: any;
  exactMatch?: boolean;
  useRouteQueryParams?: boolean;
  isLink?: boolean;

  constructor(data?) {
    data = data || {};
    this.id = data.id;
    this.label = data.label;
    this.url = data.url;
    this.redirectUrl = data.redirectUrl;
    this.queryParams = data.queryParams || {};
    this.routeQueryParams = data.routeQueryParams || {};
    this.exactMatch = data.exactMatch;
    this.useRouteQueryParams = data.useRouteQueryParams;
    this.isLink = data.isLink ?? true;
  }
}

export interface KissBreadcrumbFnData {
  route: ActivatedRouteSnapshot;
  parentUrl: string[];
  breadcrumbs: KissBreadcrumb[];
}
