import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../shared/auth/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { KissLoadingBarService } from '@kiss/components/common';
import { DeviceService } from 'app/shared/services/device/device.service';
import { TranslatePipe } from 'app/shared/services/translate/translate.pipe';

@Component({
  selector: 'Ooblee-log-in',
  templateUrl: './log-in.component.html',
  styleUrls: ['./log-in.component.scss'],
})
export class LogInComponent implements OnInit, OnDestroy {
  form: FormGroup;
  submitted = false;
  rememberMe = false;
  alert = false;
  destroy$: Subject<void>;
  constructor(
    private _fb: FormBuilder,
    private _router: Router,
    private authService: AuthenticationService,
    private toastr: ToastrService,
    private _kissLoadingBarService: KissLoadingBarService,
    private _deviceService: DeviceService,
    private _translatePipe: TranslatePipe
  ) {
    this.destroy$ = new Subject<void>();
  }

  generateUUIDv4(): string {
    return this._deviceService.generateUUIDv4();
  }

  ngOnInit(): void {
    this.form = this.createForm();
    this.detectChange();
  }

  detectChange() {
    this.form
      .get('userName')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((x) => {
        this.alert = false;
      });
    this.form
      .get('password')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((x) => {
        this.alert = false;
      });
  }

  createForm() {
    return this._fb.group({
      userName: [
        null,
        [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')],
      ],
      password: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(50)]],
      deviceId: [this.generateUUIDv4()],
    });
  }

  submit() {
    if (this.form.invalid) {
      const toastNotif = this._translatePipe.transform('notifs.allFieldsRequired');
      this.toastr.info(null, toastNotif);
    } else {
      const postData = { ...this.form.getRawValue() };

      this.authService
        .login(postData)
        .subscribe(() => {
          this._kissLoadingBarService.show();
          this.authService
            .getInitialShopData()
            .subscribe({
              next: () => {
                this._router.navigate(['landing']);
                const toastNotif = this._translatePipe.transform('logIn.successfullyLoggedIn');
                this.toastr.success(null, toastNotif);
              },
              error: () => {
                const error = this._translatePipe.transform('notifs.noShopsFound');
                this.toastr.error(null, error);
              },
            })
            .add(() => {
              this._kissLoadingBarService.hide();
            });
        })
        .add(() => {
          this._kissLoadingBarService.hide();
        });
    }
  }
  onTogglePass(elPass: HTMLInputElement) {
    elPass.type = elPass.type === 'password' ? 'text' : 'password';
  }

  toggleRemember() {
    this.rememberMe = !this.rememberMe;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete(); //  You can replace this with this.destroy$.complete() as well
  }
}
