import { Directive, EmbeddedViewRef, TemplateRef, ViewContainerRef } from '@angular/core';
import { TranslateService } from './translate.service';

@Directive({
  selector: '[translate]',
})
export class TranslateDirective {
  private _viewRef: EmbeddedViewRef<any> | null = null;

  constructor(
    private _service: TranslateService,
    private _viewContainer: ViewContainerRef,
    private _templateRef: TemplateRef<any>
  ) {
    if (!this._viewRef) {
      const ctx = this._setContext();
      this._viewRef = this._viewContainer.createEmbeddedView(this._templateRef, ctx);
    }
  }

  private _setContext() {
    return {
      $implicit: (value: string, params?: { [key: string]: string }) => {
        return this._service.translate(value, params);
      },
    };
  }
}
