import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BasicShopModel, ShopModel } from 'app/shared/models/shops-for-logged-users.model';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';
import { NotificationService } from '../notification/notification.service';
import { parseJsonString } from 'app/shared/helpers/parse-json-string';

const API_GET_SHOP_INFO = `${environment.apiShop}/shops-for-logged-user/list`;
const API_GET_SHOP = (id: number) => `${environment.apiShop}/shops-for-logged-user/${id}`;

@Injectable({ providedIn: 'root' })
export class ShopService {
  private readonly storageName = 'active_shop';
  onShopChange: BehaviorSubject<ShopModel>;

  constructor(
    private _http: HttpClient,
    private _toolbarNotificationsService: NotificationService
  ) {
    const shop = this.getShopData();
    this.onShopChange = new BehaviorSubject(shop);
  }

  saveActiveShop(data: ShopModel) {
    localStorage.setItem(this.storageName, JSON.stringify(data));
    this.onShopChange.next(data);
    this._toolbarNotificationsService.clearNotifications();
  }

  getActiveShop(): ShopModel | null {
    const storedData = localStorage.getItem(this.storageName);
    return storedData ? JSON.parse(storedData) : null;
  }

  getShopData(): ShopModel {
    const shopData = localStorage.getItem(this.storageName);
    return parseJsonString(shopData);
  }

  getShopApi() {
    return this._http.get<BasicShopModel[]>(API_GET_SHOP_INFO);
  }

  getShop(shopId: number) {
    return this._http.get<ShopModel>(API_GET_SHOP(shopId));
  }

  clearShopData() {
    localStorage.removeItem(this.storageName);
    this.onShopChange.next(undefined);
  }
}
