import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from './translate.service';

@Pipe({
  name: 'translate',
  pure: false,
})
export class TranslatePipe implements PipeTransform {
  translation: any;
  constructor(private _service: TranslateService) {}
  // add language
  transform(value: string, params?: { [key: string]: string }): string {
    return this._service.translate(value, params) || '';
  }
}
