import { Component, ElementRef, Host, Renderer2 } from "@angular/core";
import { BaseKissCellComponent } from "../base/base-kiss-cell.component";
import { KissTableDefaults } from "../helpers/kiss-table-defaults";
import { KissColumnDefDirective } from "../kiss-column/kiss-column.directive";

@Component({
  selector: "kiss-cell",
  template: "<ng-content></ng-content>",
  host: {
    class: "kiss-cell",
    role: "cell"
  }
})
export class KissCellComponent extends BaseKissCellComponent {
  constructor(
    protected override column: KissColumnDefDirective,
    protected override elementRef: ElementRef,
    protected override _renderer: Renderer2
  ) {
    super(column, elementRef, _renderer);
  }
}
