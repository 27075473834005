<button
  class="ooblee-btn__transparent ooblee-btn--bg--9 ooblee-btn--radius--50 notification__btn"
  (click)="onGoToMessages()"
>
  <kiss-svg-icon icon="envelope" class="ooblee-btn__icon ooblee-btn__icon--md"></kiss-svg-icon>
  <div class="notification__tag" *ngIf="notifications?.unseenPrivateMessageNotifications">
    {{ notifications?.unseenPrivateMessageNotifications }}
  </div>
</button>

<button
  class="ooblee-btn__transparent ooblee-btn--bg--9 ooblee-btn--radius--50 notification__btn"
  (click)="onGoToNotifications()"
>
  <kiss-svg-icon icon="bell" class="ooblee-btn__icon ooblee-btn__icon--md"></kiss-svg-icon>
  <div class="notification__tag" *ngIf="notifications?.unseenNotifications">
    {{ notifications.unseenNotifications }}
  </div>
</button>
