import { NgModule } from '@angular/core';
import { TranslatePipe } from './translate.pipe';
import { TranslateDirective } from './translate.directive';

@NgModule({
  declarations: [TranslatePipe, TranslateDirective],
  exports: [TranslatePipe, TranslateDirective],
  providers: [TranslatePipe],
})
export class TranslateModule {}
